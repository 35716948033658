.categories-list {
	max-height: 400px;
	overflow-x: auto;
	scrollbar-color: #999 map-get($theme-colors, "bgNeo");
	scrollbar-width: thin;
	&::-webkit-scrollbar-track {
		// -webkit-box-shadow: inset 0 0 6px $secondary-color;
		border-radius: 10px;
		background-color: map-get($theme-colors, "bgNeo");
	}

	&::-webkit-scrollbar {
		width: 6px;
		background-color: #999;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		// -webkit-box-shadow: inset 0 0 6px $secondary-color;
		background-color: #999;
	}
}
