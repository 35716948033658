.content-freight {
    height: 200px !important;
}
.text-freight {
    text-align: center;
    color: map-get($theme-colors, "icons");
    font-size:14px;
    font-weight:700;
    font-style: normal;
    line-height: 19px;
    margin:0;
}

/* area RulesShipping */
.content-role-freight {
    display: flex;
    flex: row;
    margin-bottom: 30px;
    border: 1px solid map-get($theme-colors, "grey-20");
    border-radius: 4px;
    box-sizing: border-box;
    .box-left {
        width: 520px;
        border-right: 1px solid map-get($theme-colors, "grey-20");
        padding: 20px;
    }

    .box-right {
        width: 520px;
        padding: 20px;
    }
}

.new-condition-freight {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid map-get($theme-colors, "grey-20");

    >button {
        background-color:map-get($theme-colors, "white");
        border: none;
        cursor: pointer;
    }
    >button:hover {
        color: map-get($theme-colors, "danger");
    }
}

@media only screen and (max-width: 960px) {
    .content-role-freight {
        .box-left {
            width: 520px;
            border-right: 1px solid map-get($theme-colors, "grey-20");
            padding: 20px;
        }
    
        .box-right {
            width: 520px;
            padding: 20px;
        }
    }
}