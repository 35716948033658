@import "variables";
@import "mixins";
@import "buttons";
@import "badges";
@import "forms";
@import "dropdown";
@import "breadcrumb";
@import "card";
@import "typography";

body {
	background: map-get($theme-colors, "bgNeo");
	color: map-get($theme-colors, "grey-80");
	font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 100;
	color: map-get($theme-colors, "titles");
}
label {
	color: map-get($theme-colors, "titles");
}
.title {
	font-weight: 300;
}
.mt-10 {
	margin-top: 10px;
}
.h-100 {
	height: 100vh !important;
}
.flex-center {
	display: flex;
	align-items: center;
}
.setup-wrapper {
	min-height: 100vh;
	padding-bottom: 75px;
	padding-top: 60px;
}
footer {
	padding: 10px;
	bottom: 10px;
	border-top: 1px solid rgba(0, 0, 0, 0.13);
}
.mt-20 {
	margin-top: 20px;
}
.help-text {
	color: map-get($theme-colors, "grey-65");

	i {
		font-size: 12px;
	}
}
.alert-neomode {
	width: 100% !important;
	border-radius: 0;
	margin-bottom: 0;
	background-color: map-get($theme-colors, "secondary");
	color: map-get($theme-colors, "white");
	width: 80%;
	text-align: center;
	font-weight: 500;
}

/* Utilities */
.container.fh {
	min-height: calc(100vh - 135px);
}
.container.sm {
	max-width: 640px;
}

.dash-content {
	margin-top: 0.5rem;
	padding-bottom: 20px;
	> .card {
		min-height: 500px;
	}
}
.bg-whhite {
	background-color: map-get($theme-colors, "white");
}
.page-404 {
	position: absolute;

	top: 0;
	bottom: 0;
	background-color: map-get($theme-colors, "bgNeo");
	height: 100%;
	width: 100%;
	padding: 5%;
	z-index: 9999;
	h1 {
		font-weight: bold;
	}
	h5 {
		padding-bottom: 10px;
		font-weight: bold;
	}
	img {
		max-width: 600px;
	}
}

.link {
	color: map-get($theme-colors, "secondary") !important;
	font-weight: bold;
	text-decoration: underline !important;
}

/* Common Components import files */
@import "components/common/htmleditor";
@import "components/common/channel";
@import "components/common/login";
@import "components/common/menu";
@import "components/common/modal";
@import "components/common/loading";
@import "components/common/dragndrop";
@import "components/common/vouchers";
@import "components/common/table";
@import "components/common/import";
@import "components/common/dropzone";
@import "components/common/preview";
@import "components/common/promotions";
@import "components/common/tutorial";
@import "components/common/categories";
@import "components/common/orders";
@import "components/common/stepsOrders";
@import "components/common/detail";
@import "components/common/collections";
@import "components/common/suggestions";
@import "components/common/calendar";
@import "components/common/appEditor";
@import "components/common/editPlugin";
@import "components/common/shipping";
@import "components/common/freight";
@import "components/common/pushNotification";
@import "components/common/rangedInput";
@import "components/common/toast";
@import "components/common/store";
@import "components/common/listComponent";
@import "components/common/autocompleteProducts";
@import "components/common/categoriesList";

/* Auto setup import files */
@import "components/autosetup/stepper";
@import "components/autosetup/cards";
@import "components/autosetup/splashscreen";
@import "components/autosetup/outlets";
@import "components/autosetup/colors";
@import "components/autosetup/welcome";
@import "components/autosetup/materials";
@import "components/autosetup/basicinfo";
@import "components/autosetup/appstore";
@import "components/autosetup/termsofuse";

/* Panel import files */
