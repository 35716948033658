/* Theme Colors */
/* Autosetup variables prefix 'as' */
.m-icon, .btn.dropdown-toggle.no-txt::after, .breadcrumb-item + .breadcrumb-item::before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased; }

.btn {
  padding: 0.55rem 1rem;
  font-size: 0.875rem;
  transition: all 0.5s ease;
  position: relative;
  border-radius: 3px !important; }
  .btn.btn-sm {
    font-size: 0.675rem; }
    .btn.btn-sm i {
      font-size: 1rem; }
  .btn.btn-lg {
    font-size: 1.15rem; }
  .btn:focus {
    outline: 0;
    box-shadow: none; }
  .btn.round {
    border-radius: 20px; }
  .btn.shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important; }
    .btn.shadow:hover {
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2) !important; }
  .btn.btn-icon-r, .btn.btn-icon-l {
    position: relative; }
    .btn.btn-icon-r i, .btn.btn-icon-l i {
      float: left;
      margin-top: 2px;
      font-size: 1.2rem;
      margin-right: 10px; }
  .btn.btn-icon-c-r, .btn.btn-icon-c-l {
    position: relative; }
    .btn.btn-icon-c-r i, .btn.btn-icon-c-l i {
      position: absolute;
      font-size: 18px;
      padding: 3px;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      line-height: 22px;
      text-align: center;
      top: 50%;
      transform: translate(0, -50%); }
  .btn.btn-icon-c-l {
    padding-left: calc(0.55rem + 38px); }
    .btn.btn-icon-c-l i {
      left: 0.55em; }
  .btn.btn-icon-c-r {
    padding-right: calc(0.55rem + 38px); }
    .btn.btn-icon-c-r i {
      right: 0.55em; }
  .btn.btn-icon {
    display: inline-flex;
    align-items: center; }
  .btn.btn-action {
    background-color: transparent !important; }
    .btn.btn-action:hover {
      background-color: transparent !important; }
  .btn.dropdown-toggle.no-txt::after {
    font-size: 16px;
    width: auto;
    height: auto;
    margin: 0;
    display: flex;
    content: "keyboard_arrow_down";
    border: 0; }
  .btn-new-width {
    height: 40px;
    min-width: 150px !important; }

.btn-primary {
  background-color: #00BFB5;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff; }
  .btn-primary.btn-icon-c-r i, .btn-primary.btn-icon-c-l i {
    background: #008c85; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    outline: 0;
    box-shadow: none;
    background-color: #00a69d !important;
    color: #ffffff; }
  .btn-primary.outline {
    border: 1px solid #00BFB5;
    background: transparent;
    color: #00BFB5; }
    .btn-primary.outline:hover {
      background: #00BFB5;
      color: #ffffff; }
  .btn-primary.glow {
    box-shadow: 0 2px 4px 0 rgba(0, 191, 181, 0.5) !important; }
    .btn-primary.glow:hover {
      box-shadow: 0 4px 12px 0 rgba(0, 191, 181, 0.6) !important; }
  .btn-primary.light {
    background-color: rgba(0, 191, 181, 0.2);
    color: #00BFB5; }
    .btn-primary.light.outline {
      border-color: rgba(0, 191, 181, 0.2);
      background: transparent;
      color: #00BFB5; }
      .btn-primary.light.outline:hover {
        color: #ffffff;
        background-color: rgba(0, 191, 181, 0.2) !important;
        border-color: transparent; }
    .btn-primary.light.btn-icon-c-r i, .btn-primary.light.btn-icon-c-l i {
      background-color: rgba(0, 191, 181, 0.3) !important; }
    .btn-primary.light:hover, .btn-primary.light:focus, .btn-primary.light:active {
      background-color: rgba(0, 191, 181, 0.3) !important;
      color: #00BFB5 !important; }
      .btn-primary.light:hover.btn-icon-c-r i, .btn-primary.light:hover.btn-icon-c-l i, .btn-primary.light:focus.btn-icon-c-r i, .btn-primary.light:focus.btn-icon-c-l i, .btn-primary.light:active.btn-icon-c-r i, .btn-primary.light:active.btn-icon-c-l i {
        background-color: rgba(0, 191, 181, 0.4) !important; }

.btn-secondary {
  background-color: #6D4AED;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff; }
  .btn-secondary.btn-icon-c-r i, .btn-secondary.btn-icon-c-l i {
    background: #481ce8; }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
    outline: 0;
    box-shadow: none;
    background-color: #5a33eb !important;
    color: #ffffff; }
  .btn-secondary.outline {
    border: 1px solid #6D4AED;
    background: transparent;
    color: #6D4AED; }
    .btn-secondary.outline:hover {
      background: #6D4AED;
      color: #ffffff; }
  .btn-secondary.glow {
    box-shadow: 0 2px 4px 0 rgba(109, 74, 237, 0.5) !important; }
    .btn-secondary.glow:hover {
      box-shadow: 0 4px 12px 0 rgba(109, 74, 237, 0.6) !important; }
  .btn-secondary.light {
    background-color: rgba(109, 74, 237, 0.2);
    color: #6D4AED; }
    .btn-secondary.light.outline {
      border-color: rgba(109, 74, 237, 0.2);
      background: transparent;
      color: #6D4AED; }
      .btn-secondary.light.outline:hover {
        color: #ffffff;
        background-color: rgba(109, 74, 237, 0.2) !important;
        border-color: transparent; }
    .btn-secondary.light.btn-icon-c-r i, .btn-secondary.light.btn-icon-c-l i {
      background-color: rgba(109, 74, 237, 0.3) !important; }
    .btn-secondary.light:hover, .btn-secondary.light:focus, .btn-secondary.light:active {
      background-color: rgba(109, 74, 237, 0.3) !important;
      color: #6D4AED !important; }
      .btn-secondary.light:hover.btn-icon-c-r i, .btn-secondary.light:hover.btn-icon-c-l i, .btn-secondary.light:focus.btn-icon-c-r i, .btn-secondary.light:focus.btn-icon-c-l i, .btn-secondary.light:active.btn-icon-c-r i, .btn-secondary.light:active.btn-icon-c-l i {
        background-color: rgba(109, 74, 237, 0.4) !important; }

.btn-success {
  background-color: #6CD691;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff; }
  .btn-success.btn-icon-c-r i, .btn-success.btn-icon-c-l i {
    background: #44cb73; }
  .btn-success:hover, .btn-success:focus, .btn-success:active {
    outline: 0;
    box-shadow: none;
    background-color: #58d082 !important;
    color: #ffffff; }
  .btn-success.outline {
    border: 1px solid #6CD691;
    background: transparent;
    color: #6CD691; }
    .btn-success.outline:hover {
      background: #6CD691;
      color: #ffffff; }
  .btn-success.glow {
    box-shadow: 0 2px 4px 0 rgba(108, 214, 145, 0.5) !important; }
    .btn-success.glow:hover {
      box-shadow: 0 4px 12px 0 rgba(108, 214, 145, 0.6) !important; }
  .btn-success.light {
    background-color: rgba(108, 214, 145, 0.2);
    color: #6CD691; }
    .btn-success.light.outline {
      border-color: rgba(108, 214, 145, 0.2);
      background: transparent;
      color: #6CD691; }
      .btn-success.light.outline:hover {
        color: #ffffff;
        background-color: rgba(108, 214, 145, 0.2) !important;
        border-color: transparent; }
    .btn-success.light.btn-icon-c-r i, .btn-success.light.btn-icon-c-l i {
      background-color: rgba(108, 214, 145, 0.3) !important; }
    .btn-success.light:hover, .btn-success.light:focus, .btn-success.light:active {
      background-color: rgba(108, 214, 145, 0.3) !important;
      color: #6CD691 !important; }
      .btn-success.light:hover.btn-icon-c-r i, .btn-success.light:hover.btn-icon-c-l i, .btn-success.light:focus.btn-icon-c-r i, .btn-success.light:focus.btn-icon-c-l i, .btn-success.light:active.btn-icon-c-r i, .btn-success.light:active.btn-icon-c-l i {
        background-color: rgba(108, 214, 145, 0.4) !important; }

.btn-danger {
  background-color: #FF5B5C;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff; }
  .btn-danger.btn-icon-c-r i, .btn-danger.btn-icon-c-l i {
    background: #ff2829; }
  .btn-danger:hover, .btn-danger:focus, .btn-danger:active {
    outline: 0;
    box-shadow: none;
    background-color: #ff4243 !important;
    color: #ffffff; }
  .btn-danger.outline {
    border: 1px solid #FF5B5C;
    background: transparent;
    color: #FF5B5C; }
    .btn-danger.outline:hover {
      background: #FF5B5C;
      color: #ffffff; }
  .btn-danger.glow {
    box-shadow: 0 2px 4px 0 rgba(255, 91, 92, 0.5) !important; }
    .btn-danger.glow:hover {
      box-shadow: 0 4px 12px 0 rgba(255, 91, 92, 0.6) !important; }
  .btn-danger.light {
    background-color: rgba(255, 91, 92, 0.2);
    color: #FF5B5C; }
    .btn-danger.light.outline {
      border-color: rgba(255, 91, 92, 0.2);
      background: transparent;
      color: #FF5B5C; }
      .btn-danger.light.outline:hover {
        color: #ffffff;
        background-color: rgba(255, 91, 92, 0.2) !important;
        border-color: transparent; }
    .btn-danger.light.btn-icon-c-r i, .btn-danger.light.btn-icon-c-l i {
      background-color: rgba(255, 91, 92, 0.3) !important; }
    .btn-danger.light:hover, .btn-danger.light:focus, .btn-danger.light:active {
      background-color: rgba(255, 91, 92, 0.3) !important;
      color: #FF5B5C !important; }
      .btn-danger.light:hover.btn-icon-c-r i, .btn-danger.light:hover.btn-icon-c-l i, .btn-danger.light:focus.btn-icon-c-r i, .btn-danger.light:focus.btn-icon-c-l i, .btn-danger.light:active.btn-icon-c-r i, .btn-danger.light:active.btn-icon-c-l i {
        background-color: rgba(255, 91, 92, 0.4) !important; }

.btn-warning {
  background-color: #FDAC41;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff; }
  .btn-warning.btn-icon-c-r i, .btn-warning.btn-icon-c-l i {
    background: #fc960f; }
  .btn-warning:hover, .btn-warning:focus, .btn-warning:active {
    outline: 0;
    box-shadow: none;
    background-color: #fda128 !important;
    color: #ffffff; }
  .btn-warning.outline {
    border: 1px solid #FDAC41;
    background: transparent;
    color: #FDAC41; }
    .btn-warning.outline:hover {
      background: #FDAC41;
      color: #ffffff; }
  .btn-warning.glow {
    box-shadow: 0 2px 4px 0 rgba(253, 172, 65, 0.5) !important; }
    .btn-warning.glow:hover {
      box-shadow: 0 4px 12px 0 rgba(253, 172, 65, 0.6) !important; }
  .btn-warning.light {
    background-color: rgba(253, 172, 65, 0.2);
    color: #FDAC41; }
    .btn-warning.light.outline {
      border-color: rgba(253, 172, 65, 0.2);
      background: transparent;
      color: #FDAC41; }
      .btn-warning.light.outline:hover {
        color: #ffffff;
        background-color: rgba(253, 172, 65, 0.2) !important;
        border-color: transparent; }
    .btn-warning.light.btn-icon-c-r i, .btn-warning.light.btn-icon-c-l i {
      background-color: rgba(253, 172, 65, 0.3) !important; }
    .btn-warning.light:hover, .btn-warning.light:focus, .btn-warning.light:active {
      background-color: rgba(253, 172, 65, 0.3) !important;
      color: #FDAC41 !important; }
      .btn-warning.light:hover.btn-icon-c-r i, .btn-warning.light:hover.btn-icon-c-l i, .btn-warning.light:focus.btn-icon-c-r i, .btn-warning.light:focus.btn-icon-c-l i, .btn-warning.light:active.btn-icon-c-r i, .btn-warning.light:active.btn-icon-c-l i {
        background-color: rgba(253, 172, 65, 0.4) !important; }

.btn-info {
  background-color: #7A9FEB;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff; }
  .btn-info.btn-icon-c-r i, .btn-info.btn-icon-c-l i {
    background: #4e7fe4; }
  .btn-info:hover, .btn-info:focus, .btn-info:active {
    outline: 0;
    box-shadow: none;
    background-color: #648fe8 !important;
    color: #ffffff; }
  .btn-info.outline {
    border: 1px solid #7A9FEB;
    background: transparent;
    color: #7A9FEB; }
    .btn-info.outline:hover {
      background: #7A9FEB;
      color: #ffffff; }
  .btn-info.glow {
    box-shadow: 0 2px 4px 0 rgba(122, 159, 235, 0.5) !important; }
    .btn-info.glow:hover {
      box-shadow: 0 4px 12px 0 rgba(122, 159, 235, 0.6) !important; }
  .btn-info.light {
    background-color: rgba(122, 159, 235, 0.2);
    color: #7A9FEB; }
    .btn-info.light.outline {
      border-color: rgba(122, 159, 235, 0.2);
      background: transparent;
      color: #7A9FEB; }
      .btn-info.light.outline:hover {
        color: #ffffff;
        background-color: rgba(122, 159, 235, 0.2) !important;
        border-color: transparent; }
    .btn-info.light.btn-icon-c-r i, .btn-info.light.btn-icon-c-l i {
      background-color: rgba(122, 159, 235, 0.3) !important; }
    .btn-info.light:hover, .btn-info.light:focus, .btn-info.light:active {
      background-color: rgba(122, 159, 235, 0.3) !important;
      color: #7A9FEB !important; }
      .btn-info.light:hover.btn-icon-c-r i, .btn-info.light:hover.btn-icon-c-l i, .btn-info.light:focus.btn-icon-c-r i, .btn-info.light:focus.btn-icon-c-l i, .btn-info.light:active.btn-icon-c-r i, .btn-info.light:active.btn-icon-c-l i {
        background-color: rgba(122, 159, 235, 0.4) !important; }

.btn-light {
  background-color: #E9ECEC;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #4B5F79; }
  .btn-light.btn-icon-c-r i, .btn-light.btn-icon-c-l i {
    background: #ced4d4; }
  .btn-light:hover, .btn-light:focus, .btn-light:active {
    outline: 0;
    box-shadow: none;
    background-color: #dbe0e0 !important;
    color: #4B5F79; }
  .btn-light.outline {
    border: 1px solid #E9ECEC;
    background: transparent;
    color: #4B5F79; }
    .btn-light.outline:hover {
      background: #E9ECEC;
      color: #4B5F79; }
  .btn-light.glow {
    box-shadow: 0 2px 4px 0 rgba(233, 236, 236, 0.5) !important; }
    .btn-light.glow:hover {
      box-shadow: 0 4px 12px 0 rgba(233, 236, 236, 0.6) !important; }
  .btn-light.light {
    background-color: rgba(233, 236, 236, 0.2);
    color: #E9ECEC; }
    .btn-light.light.outline {
      border-color: rgba(233, 236, 236, 0.2);
      background: transparent;
      color: #E9ECEC; }
      .btn-light.light.outline:hover {
        color: #ffffff;
        background-color: rgba(233, 236, 236, 0.2) !important;
        border-color: transparent; }
    .btn-light.light.btn-icon-c-r i, .btn-light.light.btn-icon-c-l i {
      background-color: rgba(233, 236, 236, 0.3) !important; }
    .btn-light.light:hover, .btn-light.light:focus, .btn-light.light:active {
      background-color: rgba(233, 236, 236, 0.3) !important;
      color: #E9ECEC !important; }
      .btn-light.light:hover.btn-icon-c-r i, .btn-light.light:hover.btn-icon-c-l i, .btn-light.light:focus.btn-icon-c-r i, .btn-light.light:focus.btn-icon-c-l i, .btn-light.light:active.btn-icon-c-r i, .btn-light.light:active.btn-icon-c-l i {
        background-color: rgba(233, 236, 236, 0.4) !important; }

.btn-titles {
  background-color: #4B5F79;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff; }
  .btn-titles.btn-icon-c-r i, .btn-titles.btn-icon-c-l i {
    background: #37465a; }
  .btn-titles:hover, .btn-titles:focus, .btn-titles:active {
    outline: 0;
    box-shadow: none;
    background-color: #415369 !important;
    color: #ffffff; }
  .btn-titles.outline {
    border: 1px solid #4B5F79;
    background: transparent;
    color: #4B5F79; }
    .btn-titles.outline:hover {
      background: #4B5F79;
      color: #ffffff; }
  .btn-titles.glow {
    box-shadow: 0 2px 4px 0 rgba(75, 95, 121, 0.5) !important; }
    .btn-titles.glow:hover {
      box-shadow: 0 4px 12px 0 rgba(75, 95, 121, 0.6) !important; }
  .btn-titles.light {
    background-color: rgba(75, 95, 121, 0.2);
    color: #4B5F79; }
    .btn-titles.light.outline {
      border-color: rgba(75, 95, 121, 0.2);
      background: transparent;
      color: #4B5F79; }
      .btn-titles.light.outline:hover {
        color: #ffffff;
        background-color: rgba(75, 95, 121, 0.2) !important;
        border-color: transparent; }
    .btn-titles.light.btn-icon-c-r i, .btn-titles.light.btn-icon-c-l i {
      background-color: rgba(75, 95, 121, 0.3) !important; }
    .btn-titles.light:hover, .btn-titles.light:focus, .btn-titles.light:active {
      background-color: rgba(75, 95, 121, 0.3) !important;
      color: #4B5F79 !important; }
      .btn-titles.light:hover.btn-icon-c-r i, .btn-titles.light:hover.btn-icon-c-l i, .btn-titles.light:focus.btn-icon-c-r i, .btn-titles.light:focus.btn-icon-c-l i, .btn-titles.light:active.btn-icon-c-r i, .btn-titles.light:active.btn-icon-c-l i {
        background-color: rgba(75, 95, 121, 0.4) !important; }

.btn-link {
  background-color: transparent;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #4B5F79; }
  .btn-link.btn-icon-c-r i, .btn-link.btn-icon-c-l i {
    background: rgba(0, 0, 0, 0); }
  .btn-link:hover, .btn-link:focus, .btn-link:active {
    outline: 0;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0) !important;
    color: #4B5F79; }
  .btn-link.outline {
    border: 1px solid transparent;
    background: transparent;
    color: #4B5F79; }
    .btn-link.outline:hover {
      background: transparent;
      color: #4B5F79; }
  .btn-link.glow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important; }
    .btn-link.glow:hover {
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.6) !important; }
  .btn-link.light {
    background-color: rgba(0, 0, 0, 0.2);
    color: transparent; }
    .btn-link.light.outline {
      border-color: rgba(0, 0, 0, 0.2);
      background: transparent;
      color: transparent; }
      .btn-link.light.outline:hover {
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.2) !important;
        border-color: transparent; }
    .btn-link.light.btn-icon-c-r i, .btn-link.light.btn-icon-c-l i {
      background-color: rgba(0, 0, 0, 0.3) !important; }
    .btn-link.light:hover, .btn-link.light:focus, .btn-link.light:active {
      background-color: rgba(0, 0, 0, 0.3) !important;
      color: transparent !important; }
      .btn-link.light:hover.btn-icon-c-r i, .btn-link.light:hover.btn-icon-c-l i, .btn-link.light:focus.btn-icon-c-r i, .btn-link.light:focus.btn-icon-c-l i, .btn-link.light:active.btn-icon-c-r i, .btn-link.light:active.btn-icon-c-l i {
        background-color: rgba(0, 0, 0, 0.4) !important; }

.btn-white {
  background-color: #ffffff;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #4B5F79; }
  .btn-white.btn-icon-c-r i, .btn-white.btn-icon-c-l i {
    background: #e6e6e6; }
  .btn-white:hover, .btn-white:focus, .btn-white:active {
    outline: 0;
    box-shadow: none;
    background-color: #f2f2f2 !important;
    color: #4B5F79; }
  .btn-white.outline {
    border: 1px solid #ffffff;
    background: transparent;
    color: #4B5F79; }
    .btn-white.outline:hover {
      background: #ffffff;
      color: #4B5F79; }
  .btn-white.glow {
    box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.5) !important; }
    .btn-white.glow:hover {
      box-shadow: 0 4px 12px 0 rgba(255, 255, 255, 0.6) !important; }
  .btn-white.light {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff; }
    .btn-white.light.outline {
      border-color: rgba(255, 255, 255, 0.2);
      background: transparent;
      color: #ffffff; }
      .btn-white.light.outline:hover {
        color: #ffffff;
        background-color: rgba(255, 255, 255, 0.2) !important;
        border-color: transparent; }
    .btn-white.light.btn-icon-c-r i, .btn-white.light.btn-icon-c-l i {
      background-color: rgba(255, 255, 255, 0.3) !important; }
    .btn-white.light:hover, .btn-white.light:focus, .btn-white.light:active {
      background-color: rgba(255, 255, 255, 0.3) !important;
      color: #ffffff !important; }
      .btn-white.light:hover.btn-icon-c-r i, .btn-white.light:hover.btn-icon-c-l i, .btn-white.light:focus.btn-icon-c-r i, .btn-white.light:focus.btn-icon-c-l i, .btn-white.light:active.btn-icon-c-r i, .btn-white.light:active.btn-icon-c-l i {
        background-color: rgba(255, 255, 255, 0.4) !important; }

.btn-default {
  background: #ffffff;
  border: 1px solid #F5F5F5; }
  .btn-default:hover {
    background: #f2f2f2; }
    .btn-default:hover.btn-icon-r i, .btn-default:hover.btn-icon-l i {
      background: #e6e6e6; }
  .btn-default.btn-icon-r i, .btn-default.btn-icon-l i {
    background: #f2f2f2; }

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px; }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E9E9E9;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: #ffffff;
    -webkit-transition: 0.4s;
    transition: 0.4s; }

input:checked + .slider {
  background-color: #00BFB5; }

input:focus + .slider {
  box-shadow: 0 0 1px #00BFB5; }

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px); }

/* Rounded sliders */
.slider.round {
  border-radius: 24px; }

.slider.round:before {
  border-radius: 50%; }

.btn.filter:before {
  display: none; }

#filter:checked ~ .btn.filter:before {
  display: block;
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-position: center center;
  content: "";
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: -30px;
  left: 0px;
  margin-top: -11px;
  animation: fadein 1s normal; }

input:focus + .slider {
  box-shadow: 0 0 1px #00BFB5; }

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px); }

/* Rounded sliders */
.slider.round {
  border-radius: 24px; }

.slider.round:before {
  border-radius: 50%; }

.btn.filter:before {
  display: none; }

#filter:checked ~ .btn.filter:before {
  display: block;
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-position: center center;
  content: "";
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: -30px;
  left: 0px;
  margin-top: -11px;
  animation: fadein 1s normal; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.btn-primary .lds-ring div,
.btn-secondary .lds-ring div,
.btn-success .lds-ring div,
.btn-danger .lds-ring div,
.btn-warning .lds-ring div,
.btn-dark .lds-ring div,
.btn-info .lds-ring div {
  border: 1px solid #ffffff;
  border-color: #ffffff transparent transparent transparent; }

.outline .lds-ring div {
  border: 1px solid;
  border-color: transparent transparent transparent; }

.badge {
  border-radius: .50rem;
  padding: .45em 1.5em;
  font-weight: normal;
  border-radius: 15px; }
  .badge.stadium {
    border-radius: 3px; }

.badge-primary {
  background-color: #00BFB5;
  color: #ffffff; }

.badge-secondary {
  background-color: #6D4AED;
  color: #ffffff; }

.badge-success {
  background-color: #6CD691;
  color: #ffffff; }

.badge-danger {
  background-color: #FF5B5C;
  color: #ffffff; }

.badge-warning {
  background-color: #FDAC41;
  color: #ffffff; }

.badge-info {
  background-color: #7A9FEB;
  color: #ffffff; }

.badge-light {
  background-color: #E9ECEC;
  color: #4B5F79; }

.badge-titles {
  background-color: #4B5F79;
  color: #ffffff; }

.form-group.icon-right i {
  position: absolute;
  top: 1.55em;
  right: 20px;
  color: #BCBEC0; }

.form-group.icon-right-error i {
  position: absolute;
  top: 1.55em;
  right: 23px;
  color: #FF5B5C; }

.form-group.icon-right-error-select i {
  position: absolute;
  top: 1.55em;
  right: 50px;
  color: #FF5B5C; }

.form-control {
  border: 2px solid #E9E9E9;
  color: #4B5F79;
  font-size: 14px; }
  .form-control:active, .form-control:focus {
    border-color: #6D4AED;
    box-shadow: 0 0 0 0.2rem rgba(109, 74, 237, 0.25);
    color: #4B5F79; }
  .form-control::placeholder {
    color: rgba(75, 95, 121, 0.4); }

.input-group .input-group-text {
  color: #4B5F79;
  background: #E9E9E9;
  border: 0; }

.custom-select {
  border: 2px solid #E9E9E9; }

.custom-select-neo {
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  border: 2px solid #E9E9E9;
  color: #747E8B;
  border-radius: 4px; }
  .custom-select-neo:active, .custom-select-neo:focus {
    border-color: #6D4AED;
    box-shadow: 0 0 0 0.2rem rgba(109, 74, 237, 0.25);
    color: #747E8B;
    outline: 0; }
  .custom-select-neo::placeholder {
    color: rgba(116, 126, 139, 0.4); }

.custom-checkbox {
  cursor: pointer; }
  .custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after {
    cursor: pointer;
    top: 50%;
    width: 17px;
    height: 17px;
    transform: translateY(-50%); }
  .custom-checkbox .custom-control-label::before {
    border: 2px solid #E9E9E9;
    background-color: #ffffff; }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background: #00BFB5;
    border-color: #00BFB5; }

.custom-radio-input {
  margin: 25px 0 !important;
  padding: 0 !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #f1f1f2; }
  .custom-radio-input input[type="radio"] {
    display: none; }
  .custom-radio-input input[type="radio"] + label span {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin: -1px 10px 0 0;
    border-radius: 50%;
    border: 2px double #bcbec0;
    cursor: pointer; }
  .custom-radio-input input[type="radio"]:checked + label span {
    border: 6px double #ffff;
    width: 20px;
    height: 20px;
    margin: 0 8px -2px -2px;
    background-color: #00BFB5; }

.autocomplete {
  position: relative; }
  .autocomplete .search {
    width: 100%; }
    .autocomplete .search:focus {
      border-color: #E9E9E9;
      box-shadow: none; }
  .autocomplete.icon-right i {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #BCBEC0; }
  .autocomplete .result {
    width: 100%;
    max-height: 400px;
    margin-top: -2px;
    overflow-y: scroll;
    position: absolute;
    background-color: #ffffff;
    padding: 0;
    border: 2px solid #E9E9E9;
    border-radius: 0px 0px 5px 5px;
    z-index: 999; }
    .autocomplete .result ul {
      list-style-type: none;
      padding: 0; }
    .autocomplete .result li {
      border-bottom: 1px solid #E9E9E9;
      padding: 10px;
      cursor: pointer; }
    .autocomplete .result li:last-child {
      border-bottom: none; }
  .autocomplete .neo-loading {
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px; }

.rw-multiselect {
  padding: 0;
  height: auto;
  border: 0; }
  .rw-multiselect.rw-state-focus .rw-widget-container, .rw-multiselect.rw-state-focus:hover .rw-widget-container {
    box-shadow: none;
    border: 2px solid #E9E9E9; }
  .rw-multiselect .rw-widget-input {
    box-shadow: none;
    border: 2px solid #E9E9E9; }
  .rw-multiselect .rw-widget-picker {
    position: relative; }
    .rw-multiselect .rw-widget-picker > * {
      display: flex;
      align-items: center; }
    .rw-multiselect .rw-widget-picker .rw-select {
      position: absolute;
      right: 10px;
      top: 0; }
    .rw-multiselect .rw-widget-picker .rw-multiselect-taglist {
      padding: 0.25rem;
      padding-bottom: 0; }
      .rw-multiselect .rw-widget-picker .rw-multiselect-taglist .rw-multiselect-tag {
        background: #6D4AED;
        border: 0;
        color: #ffffff;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0.25rem;
        margin-bottom: 0.25rem; }
        .rw-multiselect .rw-widget-picker .rw-multiselect-taglist .rw-multiselect-tag .rw-btn-select {
          opacity: 1; }

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  height: 300px !important; }

.grouped-input-before h6 {
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 600;
  color: #BCBEC0;
  opacity: 0.8; }

.grouped-input-before input {
  padding-left: 35px; }

.grouped-input-after h6 {
  position: absolute;
  margin-top: -6%;
  right: 25%;
  font-weight: 600;
  color: #BCBEC0;
  opacity: 0.8; }

.grouped-input-after input {
  padding-left: 35px; }

.section-step {
  margin-left: 20px;
  width: 100%;
  margin-bottom: 50px; }
  .section-step .number {
    position: absolute;
    left: 20px;
    background: #6D4AED;
    width: 30px;
    height: 30px;
    font-size: 18px;
    border-radius: 50%;
    text-align: center;
    color: #ffffff; }
  .section-step h3 {
    margin-left: 20px;
    font-weight: bold;
    font-size: 20px;
    color: #4B5F79; }

.react-datepicker-popper {
  width: 330px; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield; }

.react-select-container > div {
  border-radius: 4px;
  border: 2px solid #dee2e6; }
  .react-select-container > div:hover {
    border: 2px solid #dee2e6; }
  .react-select-container > div:focus-within {
    border: 2px solid #6D4AED;
    box-shadow: 0 0 0 0.2rem rgba(109, 74, 237, 0.25);
    color: #4B5F79; }
  .react-select-container > div:active {
    border: 2px solid #6D4AED;
    box-shadow: 0 0 0 0.2rem rgba(109, 74, 237, 0.25);
    color: #4B5F79; }

.search-plus-button {
  height: 38px; }

.dropdown-menu {
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
  border: 0; }
  .dropdown-menu .dropdown-item {
    font-size: .9rem;
    padding: 0.6rem 1.6rem; }
    .dropdown-menu .dropdown-item i {
      float: left;
      margin-right: 0.6rem;
      font-size: 1.3rem; }

.dropdown-menu-media {
  padding: 0;
  width: 26rem; }
  .dropdown-menu-media .dropdown-menu-header {
    border-top-left-radius: 0.267rem;
    border-top-right-radius: 0.267rem;
    background: rgba(75, 95, 121, 0.1); }
    .dropdown-menu-media .dropdown-menu-header .dropdown-header {
      padding: 0.75rem; }
      .dropdown-menu-media .dropdown-menu-header .dropdown-header .notification-title {
        font-weight: bold; }
  .dropdown-menu-media .media-list .media {
    padding: 0.75rem;
    display: flex;
    align-items: center;
    transition: all 0.5s ease;
    cursor: pointer;
    border-bottom: 1px solid rgba(75, 95, 121, 0.1); }
    .dropdown-menu-media .media-list .media:hover {
      background-color: rgba(75, 95, 121, 0.04); }
    .dropdown-menu-media .media-list .media .media-icon {
      background-color: rgba(75, 95, 121, 0.2);
      width: 40px;
      height: 40px;
      border-radius: 25px;
      position: relative;
      margin-right: 0.75rem; }
      .dropdown-menu-media .media-list .media .media-icon i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #4B5F79;
        font-size: 22px; }
    .dropdown-menu-media .media-list .media .media-body h5 {
      font-weight: bold;
      font-size: 0.9rem;
      margin: 0;
      color: #4B5F79; }
    .dropdown-menu-media .media-list .media .media-body .media-date {
      font-size: 0.7rem;
      font-weight: normal;
      color: rgba(75, 95, 121, 0.5); }
    .dropdown-menu-media .media-list .media .media-status {
      background-color: rgba(75, 95, 121, 0.1);
      width: 14px;
      height: 14px;
      border-radius: 10px;
      position: relative;
      margin-left: 0.75rem;
      position: relative; }
    .dropdown-menu-media .media-list .media.new .media-icon {
      background-color: #00BFB5; }
      .dropdown-menu-media .media-list .media.new .media-icon i {
        color: #ffffff; }
    .dropdown-menu-media .media-list .media.new .media-body h5 {
      color: #00BFB5; }
    .dropdown-menu-media .media-list .media.new .media-status:after {
      display: block;
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: #00BFB5;
      position: absolute;
      top: 50%;
      left: 50%;
      opacity: 1;
      transform: translate(-50%, -50%);
      animation: blink-animation 1s infinite;
      -webkit-animation: blink-animation 1s infinite; }

@keyframes blink-animation {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes blink-animation {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0px;
  margin-top: 0.5rem;
  padding: 0.75rem 0; }
  .breadcrumb .breadcrumb-item {
    color: #8D8D8D;
    padding-left: -50px !important; }
    .breadcrumb .breadcrumb-item.active {
      color: #6D4AED;
      padding-left: -50px !important; }

.breadcrumb-item + .breadcrumb-item::before {
  content: "";
  font-size: 14px;
  font-weight: bold;
  color: #8D8D8D; }

.link-breadcrumb {
  margin-left: -20px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .link-breadcrumb i {
    font-size: 20px;
    font-weight: 500;
    margin-right: 5px;
    color: #8D8D8D; }

.card {
  box-shadow: 0px 10px 14px 0 rgba(25, 42, 70, 0.1);
  border: 0; }
  .card .card-header {
    padding: 1.4rem;
    background-color: transparent;
    position: relative;
    border-bottom: 0; }
    .card .card-header .card-title {
      margin: 0;
      font-weight: bold;
      font-size: 24px;
      color: #4B5F79; }
  .card .card-body {
    padding: 1.4rem; }
    .card .card-body.no-padding {
      padding: 0; }

.text-primary {
  color: #00BFB5 !important; }

.text-secondary {
  color: #00BFB5 !important; }

body {
  background: #F2F4F4;
  color: #5A5E61;
  font-weight: 400; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 100;
  color: #4B5F79; }

label {
  color: #4B5F79; }

.title {
  font-weight: 300; }

.mt-10 {
  margin-top: 10px; }

.h-100 {
  height: 100vh !important; }

.flex-center {
  display: flex;
  align-items: center; }

.setup-wrapper {
  min-height: 100vh;
  padding-bottom: 75px;
  padding-top: 60px; }

footer {
  padding: 10px;
  bottom: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.13); }

.mt-20 {
  margin-top: 20px; }

.help-text {
  color: #8D8D8D; }
  .help-text i {
    font-size: 12px; }

.alert-neomode {
  width: 100% !important;
  border-radius: 0;
  margin-bottom: 0;
  background-color: #6D4AED;
  color: #ffffff;
  width: 80%;
  text-align: center;
  font-weight: 500; }

/* Utilities */
.container.fh {
  min-height: calc(100vh - 135px); }

.container.sm {
  max-width: 640px; }

.dash-content {
  margin-top: 0.5rem;
  padding-bottom: 20px; }
  .dash-content > .card {
    min-height: 500px; }

.bg-whhite {
  background-color: #ffffff; }

.page-404 {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #F2F4F4;
  height: 100%;
  width: 100%;
  padding: 5%;
  z-index: 9999; }
  .page-404 h1 {
    font-weight: bold; }
  .page-404 h5 {
    padding-bottom: 10px;
    font-weight: bold; }
  .page-404 img {
    max-width: 600px; }

.link {
  color: #6D4AED !important;
  font-weight: bold;
  text-decoration: underline !important; }

/* Common Components import files */
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: var(--ck-color-base-border);
  height: 530px; }

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-radius: var(--ck-border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: 530px; }

.sidebar a {
  color: #747E8B;
  text-decoration: none; }

.sidebar ul {
  padding-left: 0; }

.sidebar li {
  list-style-type: none;
  display: flex;
  cursor: pointer;
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: transparent; }
  .sidebar li a {
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center; }
  .sidebar li button {
    background-color: transparent;
    border: none;
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center; }
    .sidebar li button:focus {
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0; }
  .sidebar li:hover, .sidebar li.active {
    border-left-color: #00BFB5;
    color: #00BFB5; }

.sidebar i {
  margin-right: 10px; }

.channel {
  padding: 20px; }
  .channel .active {
    border-left: 3px solid #00BFB5;
    color: #00BFB5; }

.csv {
  cursor: pointer; }
  .csv input[type='file'] {
    display: none; }

.upload label {
  margin-bottom: 2.5px;
  cursor: pointer; }

.new-question {
  background: #ffffff;
  border: 1px dashed #E9E9E9;
  box-sizing: border-box;
  border-radius: 5px;
  color: #4B5F79;
  padding: 0.55rem 1rem;
  cursor: pointer;
  width: 100%; }

.title-card {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #4B5F79; }

.login {
  width: 70%;
  margin: 70px;
  display: flex;
  flex-direction: row;
  border-radius: 10px 0px 0px 10px; }
  @media (max-width: 767px) {
    .login {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 800px; } }
  .login .a {
    padding-left: 40px;
    padding-top: 10%;
    background-color: #6D4AED;
    height: 448px;
    width: 50%;
    color: #ffffff;
    font-weight: 600;
    border-radius: 10px 0 0 10px; }
    .login .a h2::after {
      display: block;
      width: 100px;
      border-bottom: 3px solid #ffffff;
      content: '';
      position: absolute;
      margin-top: 20px; }
    .login .a p {
      margin-top: 50px;
      font-size: 12px;
      line-height: 16px; }
    .login .a img {
      width: 170px; }
    @media (max-width: 767px) {
      .login .a {
        position: absolute;
        width: 80%;
        height: 200px !important;
        border-radius: 10px 10px 0px 0px;
        z-index: 1; } }
  .login .b {
    background-color: #ffffff;
    height: 448px;
    width: 50%;
    border-radius: 0 10px 10px 0;
    text-align: center;
    overflow: hidden; }
    .login .b .initial {
      width: 100%; }
    .login .b .erro i {
      font-size: 12px; }
    .login .b h2 {
      padding-bottom: 1.75rem;
      font-weight: 700; }
    .login .b h2::after {
      display: block;
      width: 50px;
      border-bottom: 3px solid #4B5F79;
      content: '';
      position: absolute;
      margin-top: 20px;
      left: 50%;
      transform: translateX(-50%); }
    .login .b .reset-password {
      color: #DBDBDB;
      font-size: 12px; }
    .login .b .footer-login {
      position: absolute;
      bottom: 20px;
      text-align: left;
      font-size: 12px;
      width: 90%;
      margin: auto; }
      .login .b .footer-login .desenvolvedor a {
        color: #DBDBDB;
        margin-left: 20px; }
      .login .b .footer-login .ajuda a {
        color: #4B5F79;
        margin-left: 5px; }
    .login .b .success {
      color: #6CD691;
      font-weight: 600; }
      .login .b .success .material-icons {
        font-size: 40px !important; }
    .login .b .top-login {
      position: absolute;
      top: 20px;
      text-align: left;
      font-size: 12px;
      width: 90%;
      margin: auto; }
      .login .b .top-login .ajuda a {
        color: #4B5F79;
        margin-left: 5px; }
    .login .b .roleContainer {
      border: 1px solid #E9E9E9;
      box-sizing: border-box;
      border-radius: 5px;
      text-align: left; }
      .login .b .roleContainer .itemRole {
        list-style: none;
        position: relative;
        border-bottom: 1px solid #E9E9E9; }
        .login .b .roleContainer .itemRole:last-child {
          border-bottom: 0; }
        .login .b .roleContainer .itemRole .name {
          font-weight: 600; }
        .login .b .roleContainer .itemRole .role {
          font-size: 12px;
          display: block; }
        .login .b .roleContainer .itemRole .go a {
          color: #00BFB5; }
        .login .b .roleContainer .itemRole img {
          width: 30px;
          height: 30px;
          border-radius: 15px; }
    @media (max-width: 767px) {
      .login .b {
        position: absolute;
        margin-top: 150px;
        width: 80%;
        border-radius: 0px 0px 10px 10px; } }

.header.header-primary {
  padding: 0;
  background-color: #6D4AED;
  position: relative; }
  .header.header-primary .navbar-toggler {
    border-color: #8061ef;
    color: #ffffff;
    display: flex;
    align-items: center; }
    .header.header-primary .navbar-toggler .material-icons {
      font-size: 20px; }
  .header.header-primary .navbar-wrapper {
    margin-left: auto; }
  .header.header-primary .brand {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .header.header-primary .dropdown-user .dropdown-menu-right {
    right: 12px;
    left: auto;
    padding: 0.5rem 0; }
  .header.header-primary .dropdown-user .dropdown-user-link::after {
    display: none; }
  .header.header-primary .dropdown-user .user-nav {
    margin-right: 0.6rem;
    text-align: right; }
    .header.header-primary .dropdown-user .user-nav .user-name {
      display: block;
      font-weight: 700; }
    .header.header-primary .dropdown-user .user-nav .user-role {
      font-size: 0.85rem; }
  .header.header-primary .dropdown-user .user-avatar img {
    border-radius: 20px;
    height: 40px;
    width: 40px; }
  .header.header-primary .nav-link {
    color: #ffffff; }
  .header.header-primary .dropdown-menu .dropdown-item {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.6rem 1.6rem;
    clear: both;
    color: #4B5F79;
    text-align: inherit;
    background-color: transparent;
    border: 0; }
    .header.header-primary .dropdown-menu .dropdown-item:hover {
      background-color: rgba(75, 95, 121, 0.05); }
  .header.header-primary .navbar-header {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0;
    z-index: 1000; }
    .header.header-primary .navbar-header .navbar-brand {
      margin-right: 0; }
  .header.header-primary .dropdown-notification .material-icons {
    font-size: 28px; }
  .header.header-primary .dropdown-notification .badge-up {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 16px;
    height: 16px;
    text-align: center;
    padding: 0;
    line-height: 16px; }

.navbar.navbar-primary {
  padding: 0;
  background-color: #6D4AED; }

.horizontal-menu {
  background-color: #F2F4F4;
  border-bottom: 1px solid #E9E9E9;
  padding: 0; }
  .horizontal-menu.navbar-expand-lg .navbar-nav > li {
    padding: 10px 0; }
  .horizontal-menu.navbar-expand-lg .navbar-nav li i {
    width: 1.6rem !important;
    margin-right: 0.4rem; }
  .horizontal-menu.navbar-expand-lg .navbar-nav li > a.nav-link {
    color: #747E8B;
    font-size: 0.9rem;
    padding-right: 0.85rem;
    padding-left: 0.85rem;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.5s ease;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
    .horizontal-menu.navbar-expand-lg .navbar-nav li > a.nav-link:hover {
      background-color: #E9ECEC; }
  .horizontal-menu.navbar-expand-lg .navbar-nav li.show > a.nav-link {
    background-color: #E9ECEC; }
  .horizontal-menu.navbar-expand-lg .navbar-nav li.dropdown {
    cursor: pointer; }
    .horizontal-menu.navbar-expand-lg .navbar-nav li.dropdown .dropdown-toggle:after {
      content: "keyboard_arrow_down";
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 16px;
      line-height: 1;
      border: 0;
      height: auto;
      margin: 0 0.55rem; }
    .horizontal-menu.navbar-expand-lg .navbar-nav li.dropdown .dropdown-menu {
      border: 1px solid #E9E9E9;
      padding: 0.7rem 0;
      top: calc(100% - 10px); }
      .horizontal-menu.navbar-expand-lg .navbar-nav li.dropdown .dropdown-menu .nav-link {
        padding-right: 2rem;
        white-space: nowrap;
        cursor: pointer; }
        .horizontal-menu.navbar-expand-lg .navbar-nav li.dropdown .dropdown-menu .nav-link i {
          width: auto;
          right: 0;
          margin: 0; }
      .horizontal-menu.navbar-expand-lg .navbar-nav li.dropdown .dropdown-menu .dropdown-item {
        font-size: 0.9rem;
        padding: 0.7rem 1.3rem; }
        .horizontal-menu.navbar-expand-lg .navbar-nav li.dropdown .dropdown-menu .dropdown-item:hover {
          background-color: rgba(233, 236, 236, 0.4); }
    .horizontal-menu.navbar-expand-lg .navbar-nav li.dropdown .dropdown-submenu > a.dropdown-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 0;
      display: inline-block;
      color: #747E8B;
      font-weight: normal;
      font-style: normal;
      margin: 0 0.55rem; }
      .horizontal-menu.navbar-expand-lg .navbar-nav li.dropdown .dropdown-submenu > a.dropdown-item:active {
        background-color: rgba(233, 236, 236, 0.4);
        color: #4B5F79; }
      .horizontal-menu.navbar-expand-lg .navbar-nav li.dropdown .dropdown-submenu > a.dropdown-item:after {
        content: "keyboard_arrow_right";
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        line-height: 1;
        border: 0;
        height: auto;
        margin: 0 0.55rem; }
    .horizontal-menu.navbar-expand-lg .navbar-nav li.dropdown .dropdown-submenu .nav-link:hover ul {
      display: block; }
    .horizontal-menu.navbar-expand-lg .navbar-nav li.dropdown .dropdown-submenu .dropdown-menu {
      left: 100%;
      top: 0; }

.dropright-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;
  color: #747E8B;
  font-weight: normal;
  font-style: normal; }
  .dropright-menu .material-icons {
    font-size: 16px;
    font-weight: normal; }

.dropdown-submenu {
  display: flex; }

.dropright {
  list-style: none;
  font-weight: normal;
  font-style: normal;
  margin: 0;
  margin-top: 30px;
  margin-left: -450px;
  padding: 5px;
  background-color: #ffffff;
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2); }
  .dropright li {
    position: relative; }
    .dropright li a {
      margin-left: -10px;
      color: #747E8B; }

.dropright-menu:hover > ul {
  display: block; }

.dropright-menu > ul {
  background-color: #ffffff;
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
  display: none;
  min-width: 160px;
  position: absolute;
  list-style: none;
  top: 5px;
  margin: 0;
  padding: 0; }

.dropright-menu .material-icons {
  position: absolute;
  font-size: 16px;
  font-weight: normal;
  right: 10px; }

ul.menu, .menu li, .menu a {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none; }

ul.menu ul {
  position: absolute;
  display: none;
  width: 230px;
  z-index: 2;
  border-radius: 5px; }

ul.menu {
  float: left;
  font-size: 12px;
  padding: 0px; }

.menu li {
  float: left;
  width: auto;
  position: relative; }

.menu li a {
  display: block;
  padding: 0 20px;
  line-height: 45px;
  height: 45px;
  float: left;
  transition: all 0.1s linear;
  border-radius: 5px; }

.menu li:hover > ul.submenu {
  display: block;
  top: 45px;
  padding: 0px; }

.submenu {
  background-color: #ffffff;
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2); }

.menu ul.submenu a {
  background-color: #ffffff;
  border: none;
  width: 230px !important;
  padding: 0px; }

.menu li:hover > ul.menu-submenu {
  display: block;
  top: 0;
  left: 230px;
  padding: 0px;
  width: 230px; }

.menu ul.menu-submenu a {
  width: 200px;
  padding: 0 20px; }

.menu ul.submenu li a {
  border-radius: 0; }

.menu ul.submenu li:last-child a {
  border-radius: 0 0 5px 5px; }

.menu ul.submenu li:first-child a {
  border-radius: 5px 5px 0 0; }

.menu a {
  color: #000; }

.menu li:hover > a {
  color: #ffffff; }

.submenu a {
  color: #ffffff;
  background-color: #747E8B; }

.submenu li:hover > a {
  background-color: #E9ECEC; }

.menu-submenu {
  background-color: #ffffff;
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2); }

.menu-submenu a {
  color: #ffffff; }

.menu-submenu li:hover > a {
  background-color: #E9ECEC; }

@media (max-width: 992px) {
  .horizontal-menu.navbar-expand-lg .navbar-nav > li {
    padding: 0; }
  ul.menu, .menu li, .menu a {
    width: 230px; }
  ul.menu ul {
    display: none;
    width: 230px;
    padding: 30px; }
  .menu li a {
    transition: all 0.1s linear; }
  ul.menu ul {
    position: static; }
  .menu li:hover > ul.menu-submenu {
    padding: 0;
    margin-left: -200px;
    margin-top: 45px; } }

.modal {
  min-height: 100vh; }
  .modal .modal-header {
    padding: 0;
    margin-bottom: 8px; }
    .modal .modal-header .modal-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #4B5F79; }
    .modal .modal-header .close {
      margin: 0;
      padding: 0; }
  .modal .modal-content {
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 31px 24px 24px 24px; }
  .modal-footer {
    padding: 0; }
  .modal-body {
    padding: 1px;
    margin-bottom: 16px; }

.fade {
  background: rgba(242, 244, 244, 0.95); }

.modal-open .modal {
  overflow-y: hidden; }

.modal-body {
  max-height: calc(100vh - 210px); }

@keyframes ldio-wbg2kxit8hs {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0; } }

.ldio-wbg2kxit8hs div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ldio-wbg2kxit8hs 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }

.ldio-wbg2kxit8hs div:nth-child(1) {
  border-color: #6D4AED; }

.ldio-wbg2kxit8hs div:nth-child(2) {
  border-color: #00BFB5;
  animation-delay: -0.5s; }

.loadingio-spinner-ripple-5l89yugl07g {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  margin: auto; }

.ldio-wbg2kxit8hs {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */ }

.ldio-wbg2kxit8hs div {
  box-sizing: content-box; }

/* generated by https://loading.io/ */
.loading-inicial {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 20000; }
  .loading-inicial > .card {
    height: 100vh; }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  margin: 1px;
  border: 1px solid;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.dragndrop-item .preview-collapse .content {
  width: calc(100% - 150px);
  padding-right: 1.75rem; }
  .dragndrop-item .preview-collapse .content h5 {
    font-weight: bold;
    font-size: 1rem;
    color: #4B5F79; }
  .dragndrop-item .preview-collapse .content .preview-body {
    font-size: .75rem;
    color: #8D8D8D; }
    .dragndrop-item .preview-collapse .content .preview-body p {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.dragndrop-item .preview-collapse .actions {
  width: 150px; }

.upload-img {
  position: relative; }
  .upload-img .box-img {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer; }
  .upload-img .close-img {
    position: absolute;
    top: -10px;
    right: -10px;
    border: none;
    background-color: #ffffff;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    border: 1px solid #EFEFEF; }
    .upload-img .close-img:hover {
      opacity: 1; }
    .upload-img .close-img:before, .upload-img .close-img:after {
      position: absolute;
      content: ' ';
      height: 10px;
      padding: -8px;
      width: 2px;
      margin-top: -5px;
      margin-left: -1px;
      background-color: #BCBEC0; }
    .upload-img .close-img:before {
      transform: rotate(45deg); }
    .upload-img .close-img:after {
      transform: rotate(-45deg); }
  .upload-img .close-img-carrousel {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background-color: #FF5B5C;
    color: #ffffff;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    padding: 0;
    /* opacity: 0.3; */
    cursor: pointer; }
    .upload-img .close-img-carrousel:hover {
      opacity: 1; }
    .upload-img .close-img-carrousel:before, .upload-img .close-img-carrousel:after {
      position: absolute;
      content: ' ';
      height: 10px;
      padding: -8px;
      width: 2px;
      margin-top: -5px;
      margin-left: -1px;
      background-color: #ffffff; }
    .upload-img .close-img-carrousel:before {
      transform: rotate(45deg); }
    .upload-img .close-img-carrousel:after {
      transform: rotate(-45deg); }

.empty .list {
  padding: 10%; }

.modal-header {
  padding-bottom: 0; }

.modal-header,
.modal-footer {
  border: none; }

.conditions {
  padding: 0px;
  height: 400px;
  overflow-y: scroll; }
  .conditions::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F2F4F4; }
  .conditions::-webkit-scrollbar {
    width: 6px;
    background-color: #F2F4F4; }
  .conditions::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #939698; }
  .conditions ul {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px; }
    .conditions ul li {
      padding: 1rem;
      border-bottom: 1px solid #E9E9E9;
      font-size: 16px;
      line-height: 18px;
      color: #4B5F79;
      cursor: pointer; }

.search-list .checkbox-list {
  height: 400px; }

.checkbox-list {
  padding: 0px;
  max-height: 400px;
  overflow-y: auto; }
  .checkbox-list::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F2F4F4; }
  .checkbox-list::-webkit-scrollbar {
    width: 6px;
    background-color: #F2F4F4; }
  .checkbox-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #939698; }
  .checkbox-list ul {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px; }
    .checkbox-list ul li {
      padding: 0.5rem 1rem;
      font-size: 16px;
      line-height: 18px;
      color: #4B5F79;
      cursor: pointer;
      border: 0; }

.conditions-button {
  margin-left: -45px;
  z-index: 1; }

.warning-discount {
  font-size: 14px; }

.voucher-price {
  height: 38px; }
  .voucher-price-input {
    font-size: 13px;
    font-weight: 500; }

.modal-footer {
  justify-content: center; }

.table.table-default.table-borderless thead {
  border: 0;
  border-top: 1px;
  border-bottom: 1px;
  border-color: #E9ECEC;
  border-style: solid; }

.table.table-default.table-hover tbody tr:hover {
  background-color: rgba(233, 236, 236, 0.3); }

.table.table-default td,
.table.table-default th {
  vertical-align: middle; }

.rdt_Table .thumb-product {
  width: auto;
  max-width: 80px;
  height: auto;
  max-height: 80px; }

.rdt_TableRow {
  overflow: visible !important; }

.rdt_TableCell div:first-child {
  overflow: visible !important;
  word-wrap: break-word !important; }

.product-image {
  min-height: 80px;
  display: flex;
  align-items: center; }
  .product-image img {
    max-height: 80px;
    max-width: 80px; }

.importItem {
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  padding: 1.4rem; }
  .importItem .header {
    display: flex;
    align-items: center;
    margin-bottom: 1.4rem; }
    .importItem .header .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: #00BFB5;
      float: left;
      margin-right: 1.4rem;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      color: #ffffff; }
    .importItem .header .title {
      font-weight: bold;
      color: #4B5F79;
      display: flex;
      align-items: center; }
      .importItem .header .title .help {
        margin-left: 3px;
        height: 16px;
        color: #6D4AED; }
        .importItem .header .title .help i {
          font-size: 16px; }
    .importItem .header .attachment {
      color: #8D8D8D;
      font-size: 90%;
      text-decoration: underline; }
  .importItem .file-button {
    display: flex;
    justify-content: center; }
    .importItem .file-button .btn-primary input[type="file"] {
      display: none; }

.history-import .progress {
  margin: auto;
  background-color: #E9ECEC;
  border-radius: 3px;
  color: #DBDBDB;
  text-transform: lowercase;
  margin-top: 10px; }
  .history-import .progress .progress-bar {
    z-index: 0;
    border-radius: 3px; }
  .history-import .progress small {
    margin-left: 2px;
    position: absolute;
    margin-left: 3%;
    z-index: 0; }
  .history-import .progress i {
    position: absolute;
    right: 16.5%;
    font-size: 14px; }

#details .download-archive {
  position: absolute;
  margin-top: -5%;
  right: 3%;
  color: #4B5F79; }
  #details .download-archive span {
    font-size: 19px;
    padding-top: 3px;
    position: absolute;
    margin-left: -21px;
    line-height: 14px; }

#details .log-erro {
  text-align: right;
  padding-right: 5px; }
  #details .log-erro i {
    font-size: 14px; }
  #details .log-erro a {
    margin-left: 10px;
    color: #DBDBDB;
    font-size: 12px;
    text-decoration: underline; }

.bg-primary {
  background-color: #00BFB5 !important; }

.import a {
  margin-left: -14px;
  text-decoration: underline; }

.import .import-details-body {
  margin-top: 10px;
  padding: 3%;
  border-radius: 5px;
  background: #E9ECEC;
  font-family: "Roboto Mono", monospace;
  text-align: start; }
  .import .import-details-body p {
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: #4B5F79; }

.import-list-log-files {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 30vh;
  scrollbar-color: #999 #F2F4F4;
  scrollbar-width: thin; }
  .import-list-log-files::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F2F4F4; }
  .import-list-log-files::-webkit-scrollbar {
    width: 6px;
    background-color: #999; }
  .import-list-log-files::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #999; }

.dropzone {
  border: 0;
  padding: 0;
  height: auto;
  display: flex;
  cursor: default;
  flex-wrap: wrap; }
  .dropzone .upload-img {
    width: 180px;
    height: 180px;
    background-size: cover;
    background-position: center;
    border: 3px solid #E9E9E9;
    border-radius: 5px;
    margin-right: 0.75rem;
    cursor: move;
    margin-bottom: 0.75em; }
  .dropzone .hover-input .box-img {
    border: 3px dashed #E9E9E9;
    border-radius: 5px;
    width: 180px;
    height: 180px;
    box-shadow: none;
    color: #4B5F79;
    margin-bottom: 0.75em;
    cursor: pointer; }

.preview.app {
  background: #E9E9E9;
  box-shadow: 0 0 0 5px #E9E9E9;
  border: 1px solid gainsboro;
  border-radius: 35px;
  padding: 7px;
  overflow: hidden;
  width: 266px; }
  .preview.app .body {
    width: 250px;
    height: 520px;
    background: #ffffff;
    border-radius: 25px;
    overflow: hidden;
    overflow-y: auto;
    position: relative; }
    .preview.app .body::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #F2F4F4; }
    .preview.app .body::-webkit-scrollbar {
      display: none;
      width: 6px;
      background-color: #999; }
    .preview.app .body::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #999; }
  .preview.app .app-bar {
    padding-top: 25px;
    background-color: #E9E9E9;
    height: 65px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%; }
    .preview.app .app-bar [class^='action-'] {
      flex: 1;
      display: flex;
      color: #ffffff; }
    .preview.app .app-bar .action-left {
      padding-left: 10px;
      justify-content: flex-start; }
    .preview.app .app-bar .action-center {
      justify-content: center; }
    .preview.app .app-bar .action-right {
      justify-content: flex-end;
      padding-right: 10px; }

.preview .bottom-navigation {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  height: 45px;
  padding-bottom: 15px;
  padding-top: 5px;
  border-top: 1px solid #E9E9E9; }
  .preview .bottom-navigation .action-item {
    flex: 1;
    color: #8D8D8D;
    text-align: center; }
    .preview .bottom-navigation .action-item .material-icons {
      font-size: 20px;
      margin: 0;
      display: block;
      line-height: 18px; }
    .preview .bottom-navigation .action-item .label {
      display: block;
      font-size: 8px; }

.preview .app-content {
  position: absolute;
  top: 65px;
  bottom: 45px;
  width: 100%;
  overflow-y: auto; }
  .preview .app-content::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F2F4F4; }
  .preview .app-content::-webkit-scrollbar {
    width: 0px;
    background-color: #F2F4F4; }
  .preview .app-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #999; }

.preview .app-carousel-banner {
  height: 160px;
  background: #E9E9E9;
  position: relative; }
  .preview .app-carousel-banner .carousel-inner {
    height: 100%; }
    .preview .app-carousel-banner .carousel-inner .carousel-item {
      height: 100%; }
      .preview .app-carousel-banner .carousel-inner .carousel-item .banner {
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex: 1;
        color: #999; }

.preview .app-list-banners {
  padding: 10px; }
  .preview .app-list-banners ul {
    padding: 0;
    margin: 0;
    list-style-type: none; }
    .preview .app-list-banners ul li {
      margin-bottom: 10px; }
      .preview .app-list-banners ul li .banner-body {
        background-size: cover;
        background-repeat: no-repeat;
        height: 140px;
        background-position: center;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 4px rgba(0, 0, 0, 0.15);
        background-color: #dee2e6; }
      .preview .app-list-banners ul li:last-child {
        margin-bottom: 0; }

.tutorial-title {
  color: #ffffff;
  text-align: center;
  padding-top: 21px;
  line-height: 16px;
  width: 152px;
  margin: auto;
  font-size: 14px; }
  .tutorial-title span {
    font-size: 35px;
    margin-bottom: 20px; }

.tutorial-content {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding: 19px; }

.list-banner {
  display: flex; }
  .list-banner .banner-img > img {
    border-radius: 2px;
    margin: 5px;
    width: 70px;
    height: 70px; }
  .list-banner .banner-description {
    margin: 5px;
    width: 340px; }
    .list-banner .banner-description > label, .list-banner .banner-description h6 {
      margin: 0;
      padding: 0; }
  .list-banner .banner-label {
    display: flex;
    align-items: center;
    margin: 5px;
    width: 200px;
    padding: 0 10px; }
  .list-banner .banner-buttons {
    display: flex;
    align-items: center;
    margin: 5px; }

@media only screen and (max-width: 1200px) {
  .list-banner {
    display: flex; }
    .list-banner .banner-description {
      width: 200px; }
    .list-banner .banner-label {
      align-items: center;
      width: 100px; } }

@media only screen and (max-width: 996px) {
  .list-banner {
    display: flex;
    flex-direction: column; }
    .list-banner .banner-description {
      width: 100%; }
    .list-banner .banner-label {
      margin: 0;
      padding-left: 5px; } }

.card-promotions {
  background: #ffffff;
  border: 2px solid #E9E9E9;
  box-sizing: border-box;
  border-radius: 5px; }
  .card-promotions p {
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #4B5F79;
    margin-bottom: 20px; }
  .card-promotions .close-img {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background-color: #FF5B5C;
    color: #ffffff;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    padding: 0;
    /* opacity: 0.3; */
    cursor: pointer; }
    .card-promotions .close-img:hover {
      opacity: 1; }
    .card-promotions .close-img:before, .card-promotions .close-img:after {
      position: absolute;
      content: ' ';
      height: 10px;
      padding: -8px;
      width: 2px;
      margin-top: -5px;
      margin-left: -1px;
      background-color: #ffffff; }
    .card-promotions .close-img:before {
      transform: rotate(45deg); }
    .card-promotions .close-img:after {
      transform: rotate(-45deg); }

.title-tutorial {
  font-size: 16px !important; }

.description-tutorial {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
  color: #DBDBDB; }

.category {
  width: 330px;
  height: 40px;
  background: #747e8b;
  border-radius: 3px;
  color: #fff;
  padding: 16px;
  margin: 5px 0; }
  .category-son {
    margin: 5px 0;
    padding: 16px;
    width: 314px;
    height: 40px;
    background: #bcbec0;
    border-radius: 3px; }
  .category-grandchild {
    margin: 5px 0;
    padding: 16px;
    width: 298px;
    height: 40px;
    background: #ececec;
    border-radius: 3px; }
  .category-great-grandchild {
    margin: 5px 0;
    padding: 16px;
    width: 282px;
    height: 40px;
    border-radius: 3px;
    background-color: #f1f1f2;
    margin-right: 26px; }
  .category-card {
    max-height: 820px;
    overflow: auto; }
    .category-card::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #F2F4F4; }
    .category-card::-webkit-scrollbar {
      width: 0px;
      background-color: #F2F4F4; }
  .category .sidebar-list {
    background-color: #F2F4F4;
    height: 100%;
    border: none;
    padding: 10px; }
    .category .sidebar-list .category-name {
      background-color: #ffffff;
      padding-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 8px;
      border-radius: 5px;
      cursor: auto; }
      .category .sidebar-list .category-name::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #999; }
  .category .add-subcategory {
    height: 18px;
    width: 18px;
    background: #00BFB5;
    opacity: 0.3;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    transition: 0.5s; }
    .category .add-subcategory:focus {
      transition: 0.5s;
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
      background: #00bfb5; }
    .category .add-subcategory:active {
      transition: 0.5s;
      background: #00bfb5; }
    .category .add-subcategory:hover {
      transition: 0.5s;
      background: #00bfb5;
      opacity: 1; }
  .category .drop-body {
    height: calc(20em + 20em); }
  .category .aux-text {
    color: #747E8B;
    font-size: 12px; }
  .category i {
    cursor: pointer; }
  .category i:hover {
    color: #d3d3d3; }
  .category button {
    width: 50px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 2px; }
  .category .icon {
    height: 96px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
  .category .inserted-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    border: 2px solid #E9ECEC;
    padding: 11px;
    border-radius: 5px; }
    .category .inserted-image-infos {
      display: flex; }
    .category .inserted-image-button {
      display: flex; }
  .category .new-category {
    background-color: #F2F4F4;
    color: #4B5F79;
    border: 2px dashed rgba(116, 126, 139, 0.3);
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer; }
  .category .new-category i {
    color: #4B5F79; }
  .category .new-subcategory {
    background-color: #F2F4F4;
    color: #4B5F79;
    width: 100%;
    border: 2px dashed rgba(116, 126, 139, 0.3);
    box-sizing: border-box; }

.category-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 50px; }

.category-body p {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #4B5F79;
  width: 400px; }

.category-title {
  font-size: 24px !important; }

/* Metrcis */
.metrics-orders select {
  width: 160px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #4B5F79; }

.metrics-orders > p {
  color: #DBDBDB;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  padding: 0 10px; }

.metrics {
  width: 130px; }
  .metrics .metrics-title {
    color: #DBDBDB;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px; }
  .metrics .metrics-value {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #DBDBDB; }
  .metrics .metrics-icons-a {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #6CD691;
    display: flex;
    justify-content: start;
    align-items: center;
    align-content: center; }
  .metrics .metrics-icons-b {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #FF5B5C;
    display: flex;
    justify-content: start;
    align-items: center;
    align-content: center; }

.filter-order-spdp {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px; }
  .filter-order-spdp > div {
    width: 350px; }

.filter-search-spdp {
  width: 522px; }

.btn-filtered {
  background-color: #00BFB5;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  height: 26px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 2px 5px;
  margin-right: 8px; }
  .btn-filtered > i {
    font-size: 18px;
    margin-left: 2px; }

.btn-filtered:focus {
  outline: thin dotted;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: 0px; }

@media (max-width: 892px) {
  .filter-order-spdp > div {
    width: 100%; }
  .filter-search-spdp {
    margin-top: 10px;
    width: 100%; } }

@media (max-width: 550px) {
  .filter-orders {
    flex-direction: column;
    align-items: center; }
    .filter-orders .filter-date {
      width: 100%;
      margin-bottom: 10px; }
    .filter-orders .filter-btn {
      width: 100%; } }

.order-list-client {
  line-height: 12px;
  font-size: 12px; }
  .order-list-client p:last-child {
    margin-bottom: 0; }
  .order-list-client-name {
    color: #6D4AED;
    font-weight: bold; }

.order-list-order {
  line-height: 12px;
  font-size: 12px; }
  .order-list-order p:last-child {
    margin-bottom: 0; }
  .order-list-order-code {
    font-weight: bold;
    height: 12px; }

.order-list-status {
  line-height: 14px;
  font-size: 12px;
  color: #747E8B;
  text-align: center; }
  .order-list-status p:last-child {
    margin-bottom: 0; }
  .order-list-status-Canceled {
    color: #FF5B5C; }
  .order-list-status-InProcessing {
    color: #FDAC41; }
  .order-list-status-Finalized {
    color: #6CD691; }

.order-list-products {
  display: flex;
  background: #BCBEC0;
  border-radius: 33px;
  color: #ffffff;
  width: 56px;
  height: 24px;
  align-self: center;
  justify-content: center;
  align-items: center;
  line-height: 24px; }
  .order-list-products p:last-child {
    margin-bottom: 0; }

.order-list-modalities {
  line-height: 14px;
  font-size: 12px; }
  .order-list-modalities p:last-child {
    margin-bottom: 0; }
  .order-list-modalities-title {
    font-weight: bold;
    word-break: keep-all;
    width: 8em;
    height: 1.8em; }

.order-list-origin {
  line-height: 12px;
  font-size: 12px; }
  .order-list-origin p:last-child {
    margin-bottom: 0; }
  .order-list-origin-title {
    font-weight: bold;
    line-height: 20px; }

.order-list-total {
  line-height: 12px;
  font-size: 12px; }
  .order-list-total p:last-child {
    margin-bottom: 0; }

.order-list-payment {
  display: flex;
  line-height: 14px;
  font-size: 12px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  align-self: center; }
  .order-list-payment p:last-child {
    margin-bottom: 0; }
  .order-list-payment span {
    margin-bottom: 0.8em; }

.order-list-details {
  color: #6D4AED;
  text-align: center; }

.filter-orders {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
  flex-direction: row; }
  .filter-orders .filter-date {
    width: 550px; }
    .filter-orders .filter-date > label {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: #474162; }
    .filter-orders .filter-date .group-btn {
      display: flex;
      flex-direction: row; }
      .filter-orders .filter-date .group-btn > button {
        display: inline-block;
        cursor: pointer;
        box-shadow: 0 0 0 0;
        outline: 0;
        background: transparent;
        height: 38px;
        padding: 0 10px;
        border: 1px solid #dee2e6;
        color: #c4c4c4;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase; }
        .filter-orders .filter-date .group-btn > button + button {
          border-left: none;
          flex: 1; }
        .filter-orders .filter-date .group-btn > button:first-child {
          border-radius: 5px 0 0 5px; }
      .filter-orders .filter-date .group-btn .dropdown {
        display: inline-block;
        cursor: pointer;
        box-shadow: 0 0 0 0;
        outline: 0;
        background: transparent;
        height: 38px;
        padding: 0 0px;
        border: 1px solid #dee2e6;
        color: #c4c4c4;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 0 5px 5px 0; }
        .filter-orders .filter-date .group-btn .dropdown + button {
          border-left: none;
          flex: 1; }
        .filter-orders .filter-date .group-btn .dropdown .dropdown-toggle {
          background-color: transparent;
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase; }
          .filter-orders .filter-date .group-btn .dropdown .dropdown-toggle .material-icons {
            font-size: 16px; }
          .filter-orders .filter-date .group-btn .dropdown .dropdown-toggle span {
            justify-self: center; }
      .filter-orders .filter-date .group-btn .active-data-btn {
        background-color: #01bfb5;
        color: #fff;
        border: none;
        height: 38px; }
        .filter-orders .filter-date .group-btn .active-data-btn > .calendar-toggle:active {
          background-color: #01bfb5;
          color: #fff;
          border: none;
          margin: none;
          height: 37px;
          border-radius: 0 5px 5px 0 !important; }
  .filter-orders .filter-search {
    width: 350px; }
  .filter-orders .filter-btn {
    display: flex;
    flex-direction: row; }
    .filter-orders .filter-btn .import {
      height: 42px;
      display: flex;
      background: rgba(108, 214, 145, 0.3);
      border-radius: 3px;
      color: #6cd691; }

.progress-orders .text-link-timeline {
  border: none;
  background: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: #747E8B;
  cursor: pointer; }

.progress-orders .text-link-timeline:focus {
  border: none;
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0; }

.progress-orders .timeline {
  padding: 0;
  height: 60px;
  display: flex;
  margin-top: 30px;
  margin-left: -20px; }
  .progress-orders .timeline .btn-light, .progress-orders .timeline .btn-primary {
    width: 300px; }
  .progress-orders .timeline ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    text-transform: uppercase; }
    .progress-orders .timeline ul::before {
      width: 77%;
      height: 3px;
      background: #efefef;
      content: "";
      display: block;
      position: absolute;
      top: 122px; }
    .progress-orders .timeline ul li {
      font-size: 12px;
      text-align: center;
      flex: 1;
      position: relative; }
      .progress-orders .timeline ul li span {
        display: block;
        margin-top: 20px;
        color: #9b9b9b;
        height: 100px;
        font-size: 11px;
        width: 145px; }
      .progress-orders .timeline ul li::before {
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        display: block;
        content: "";
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border: 2px solid #efefef;
        background: #fff; }
      .progress-orders .timeline ul li.started::before {
        border: 2px solid #DBDBDB;
        background: #DBDBDB;
        z-index: 2; }
      .progress-orders .timeline ul li.started::after {
        width: 100%;
        height: 3px;
        content: "";
        position: absolute;
        top: 7px; }
      .progress-orders .timeline ul li.started span {
        color: #DBDBDB;
        font-size: 11px;
        font-weight: bold;
        width: 145px; }
      .progress-orders .timeline ul li.validated::before {
        border: 2px solid #00BFB5;
        background: #00BFB5;
        z-index: 2; }
      .progress-orders .timeline ul li.validated::after {
        width: 100%;
        height: 3px;
        content: "";
        position: absolute;
        top: 7px; }
      .progress-orders .timeline ul li.validated span {
        color: #00BFB5;
        font-size: 11px;
        font-weight: bold;
        width: 145px; }
      .progress-orders .timeline ul li.success::before {
        border: 2px solid #00BFB5;
        background: #00BFB5;
        z-index: 2; }
      .progress-orders .timeline ul li.success::after {
        width: 95%;
        height: 3px;
        background: #00BFB5;
        content: "";
        display: block;
        position: absolute;
        top: 7px;
        right: 70px;
        z-index: 1; }
      .progress-orders .timeline ul li.success span {
        color: #00BFB5;
        font-size: 11px;
        font-weight: bold;
        width: 145px; }
      .progress-orders .timeline ul li.waiting::before {
        border: 2px solid #DBDBDB;
        background: #DBDBDB;
        z-index: 2; }
      .progress-orders .timeline ul li.waiting::after {
        width: 95%;
        height: 3px;
        background: #DBDBDB;
        content: "";
        display: block;
        position: absolute;
        top: 7px;
        right: 70px;
        z-index: 1; }
      .progress-orders .timeline ul li.waiting span {
        color: #DBDBDB;
        font-size: 11px;
        font-weight: bold;
        width: 145px; }
      .progress-orders .timeline ul li.err::before {
        border: 2px solid #FF5B5C;
        background: #FF5B5C;
        z-index: 2; }
      .progress-orders .timeline ul li.err::after {
        width: 95%;
        height: 3px;
        background: #FF5B5C;
        content: "";
        display: block;
        position: absolute;
        top: 7px;
        right: 70px;
        z-index: 1; }
      .progress-orders .timeline ul li.err span {
        color: #FF5B5C;
        font-size: 11px;
        font-weight: bold;
        width: 145px; }
      .progress-orders .timeline ul li.warning::before {
        border: 2px solid #FDAC41;
        background: #FDAC41;
        z-index: 2; }
      .progress-orders .timeline ul li.warning::after {
        width: 95%;
        height: 3px;
        background: #FDAC41;
        content: "";
        display: block;
        position: absolute;
        top: 7px;
        right: 70px;
        z-index: 1; }
      .progress-orders .timeline ul li.warning span {
        color: #FDAC41;
        font-size: 11px;
        font-weight: bold;
        width: 145px; }

.detail {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 3px solid #F5F5F5; }
  .detail .detail-tabs button {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #4B5F79;
    width: auto;
    border: none;
    padding: 0 2.5em 15px 2.5em;
    background: transparent;
    cursor: pointer; }
  .detail .detail-tabs button:focus {
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px; }
  .detail .detail-tabs .actived {
    border-bottom: 4px solid #6D4AED;
    color: #6D4AED; }
  .detail .detail-invoice {
    margin-right: 6px; }
    .detail .detail-invoice > button {
      display: flex;
      justify-content: center;
      align-content: center;
      padding: 8px 10px;
      cursor: pointer;
      box-shadow: 0 0 0 0;
      outline: 0;
      border: none;
      background: rgba(122, 159, 235, 0.3);
      border-radius: 5px; }
      .detail .detail-invoice > button > i {
        color: #7A9FEB;
        font-size: 20px; }

.detail-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px; }
  .detail-container .box-one {
    width: 32.6%;
    height: 250px;
    padding: 25px 20px; }
    .detail-container .box-one .box-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      margin-bottom: 15px; }
      .detail-container .box-one .box-title h2 {
        padding-top: 5px;
        font-weight: bold;
        font-size: 17px; }
      .detail-container .box-one .box-title .success {
        margin-left: 8px;
        background: #6CD691;
        color: #ffffff;
        text-align: center;
        padding: 10px 10px;
        border-radius: 5px;
        font-weight: 500; }
      .detail-container .box-one .box-title .warning {
        margin-left: 8px;
        background: #FDAC41;
        color: #ffffff;
        text-align: center;
        padding: 10px 10px;
        border-radius: 5px;
        font-weight: 500; }
      .detail-container .box-one .box-title .danger {
        margin-left: 8px;
        background: #FF5B5C;
        color: #ffffff;
        text-align: center;
        padding: 10px 10px;
        border-radius: 5px;
        font-weight: 500; }
      .detail-container .box-one .box-title label {
        padding: 10px;
        border-radius: 5px;
        background: #F2F4F4;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        color: #4B5F79; }
    .detail-container .box-one .text-orders {
      margin: 0; }
    .detail-container .box-one span {
      font-size: 14px;
      color: #4B5F79;
      margin-bottom: 8px; }
  .detail-container div + div {
    margin-left: 15px; }
  .detail-container .box-two {
    width: 68%;
    height: 250px;
    padding: 25px 20px;
    display: flex;
    flex: 1; }
    .detail-container .box-two .status {
      display: flex;
      justify-content: space-between; }
      .detail-container .box-two .status label {
        padding: 10px;
        border-radius: 5px;
        font-weight: 500; }
  .detail-container .box-three {
    width: 100%;
    padding: 25px 20px; }
    .detail-container .box-three .box-container-orders {
      display: flex; }
      .detail-container .box-three .box-container-orders .box-header {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 40%; }
        .detail-container .box-three .box-container-orders .box-header .box-img-orders {
          margin-top: 20px;
          margin-left: 7px;
          margin-bottom: 5px;
          max-width: 50px;
          max-height: 300px; }
          .detail-container .box-three .box-container-orders .box-header .box-img-orders > img {
            width: 100px;
            max-width: 300px;
            max-height: 300px; }
      .detail-container .box-three .box-container-orders .box-body {
        width: 100%;
        display: flex;
        flex-direction: row; }
        .detail-container .box-three .box-container-orders .box-body > div {
          display: flex;
          flex-direction: column;
          margin: 20px;
          padding: 10px 30px;
          color: #747E8B;
          font-size: 14px;
          line-height: 16px; }
    .detail-container .box-three .box-table {
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      .detail-container .box-three .box-table .total {
        width: 35%;
        padding-right: 30px;
        padding-bottom: 20px;
        border-bottom: 1px dashed #E9ECEC;
        display: flex;
        justify-content: flex-end; }
        .detail-container .box-three .box-table .total > div {
          display: flex;
          flex-direction: column; }
          .detail-container .box-three .box-table .total > div .title-1 {
            padding-right: 100px;
            color: #747E8B; }
          .detail-container .box-three .box-table .total > div .title-2 {
            display: flex;
            justify-content: flex-end; }

/* area do historico */
.card-historic-description {
  padding: 10px 20px;
  color: #DBDBDB;
  font-size: 12px;
  font-weight: 400; }

.card-historic {
  border: 1px solid #F5F5F5;
  border-radius: 5px; }
  .card-historic .card-historic-title {
    padding: 8px 20px;
    color: #747E8B;
    font-size: 14px;
    font-weight: bold; }

.order-label {
  text-transform: uppercase;
  height: 80px; }
  .order-label > h2 {
    margin: 0; }
    .order-label > h2 label {
      margin: 0; }
  .order-label > span {
    color: #4B5F79;
    font-size: 12px; }

@media only screen and (max-width: 1200px) {
  /* .detail-container .box-two .status label {
        padding: 3px 0;
    } */
  .order-label > span {
    margin-top: 3px;
    margin-bottom: 5px; } }

@media only screen and (max-width: 990px) {
  .detail-container {
    flex-direction: column; }
    .detail-container .box-one {
      width: 100%;
      margin: 0;
      margin-bottom: 15px; }
    .detail-container div + div {
      margin-bottom: 15px; }
    .detail-container .box-two {
      width: 100%;
      margin: 0; }
    .detail-container .box-three {
      width: 100%;
      margin-top: -10px; }
      .detail-container .box-three .box-container-orders {
        display: flex;
        flex-direction: column; }
        .detail-container .box-three .box-container-orders .box-header {
          width: 100%; }
        .detail-container .box-three .box-container-orders .box-body {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-left: 0; }
          .detail-container .box-three .box-container-orders .box-body > div {
            display: flex;
            flex-direction: column;
            margin: 5px 20px;
            padding: 0;
            color: #747E8B;
            font-size: 14px;
            line-height: 16px; } }

@media only screen and (max-width: 767px) {
  .order-label > span {
    margin-top: 5px;
    margin-bottom: 0; } }

.download-pdf-xml {
  background: #ffffff;
  border: 3px dashed #F5F5F5;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  cursor: pointer;
  height: 50px; }
  .download-pdf-xml p {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    font-weight: 500;
    cursor: pointer;
    color: #a1a3a5; }
    .download-pdf-xml p i {
      cursor: pointer;
      font-size: 25px;
      color: #a1a3a5; }
  .download-pdf-xml .success {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 0;
    margin: 0; }
    .download-pdf-xml .success .success-icon {
      color: #98ff98;
      font-size: 40px; }

.download-pdf-xml:active {
  background: rgba(122, 159, 235, 0.3); }

.download-group {
  border: 2px solid #F5F5F5;
  height: 40px;
  display: flex;
  justify-content: space-between; }
  .download-group .download-group-label {
    padding: 0 10px;
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center; }
    .download-group .download-group-label > i {
      padding-right: 10px;
      color: #747E8B; }
  .download-group .download-group-icons {
    padding: 0 10px;
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center; }
    .download-group .download-group-icons > button {
      cursor: pointer;
      padding-top: 10px;
      background: transparent;
      border: none;
      outline: none; }
    .download-group .download-group-icons > a {
      cursor: pointer;
      padding-top: 10px;
      color: #747E8B; }

.date-orders {
  width: 215px;
  background: #BCBEC0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  border-radius: 4px; }
  .date-orders i {
    color: #4B5F79;
    padding: 8px 9px;
    font-size: 20px; }

.status-select, .status-select:focus {
  width: 200px;
  padding: 12px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0; }
  .status-select option, .status-select option:focus, .status-select:focus option, .status-select:focus option:focus {
    padding: 10px 50px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0; }

.separete {
  background-color: #4B5F79;
  color: #ffffff; }

.separete:hover {
  background-color: #4B5F79 !important; }

.separete:active {
  background-color: #4B5F79 !important; }

.separete:focus {
  background-color: #4B5F79 !important; }

.counter-price {
  display: flex;
  justify-content: center;
  align-items: center; }
  .counter-price > button {
    color: #4B5F79;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    text-align: center;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px; }
  .counter-price > input {
    width: 50px;
    height: 30px;
    border: none;
    padding: 5px;
    text-align: center;
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px; }

.edit-price {
  max-width: 150px; }
  .edit-price .value-price {
    display: flex;
    padding: 0;
    margin: auto 0; }
    .edit-price .value-price > label {
      position: relative;
      top: 6px;
      left: 25px; }
    .edit-price .value-price > input {
      padding: 5px 0 5px 27px;
      border: 1px solid #00BFB5;
      box-sizing: border-box;
      border-radius: 5px !important; }
    .edit-price .value-price > input:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px; }
  .edit-price .btn-price {
    display: flex;
    justify-content: flex-end;
    position: relative; }
    .edit-price .btn-price > div {
      position: absolute;
      display: flex; }
      .edit-price .btn-price > div > button {
        padding: 0;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .edit-price .btn-price > div > button > i {
          font-size: 20px; }

.btn-sku-default {
  background: #F2F4F4;
  border: solid 3px #F2F4F4;
  margin-right: 5px;
  font-weight: 400;
  width: 64px;
  height: 40px;
  border-radius: 3px;
  cursor: pointer; }

.btn-sku-default:hover {
  background: #00BFB5;
  border: solid 3px #00BFB5;
  color: #ffffff;
  font-weight: 500; }

.btn-sku-default:focus {
  outline: thin dotted;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: 0px; }

.btn-sku-active {
  background: #00BFB5;
  border: solid 3px #00BFB5;
  color: #ffffff;
  margin-right: 5px;
  font-weight: 400;
  width: 64px;
  height: 40px;
  border-radius: 3px;
  cursor: pointer; }

.btn-sku-active:focus {
  outline: thin dotted;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: 0px; }

.btn-color-default {
  background: #F2F4F4;
  border: solid 3px #F2F4F4;
  font-weight: 400;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .btn-color-default > button {
    border-radius: 3px;
    border: none;
    width: 30px;
    height: 30px;
    cursor: pointer; }
  .btn-color-default > button:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px; }

.btn-color-default:hover {
  border: solid 3px #00BFB5;
  border-radius: 3px; }

.btn-color-active {
  background: #F2F4F4;
  border: solid 3px #00BFB5;
  border-radius: 3px;
  font-weight: 400;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .btn-color-active > button {
    border-radius: 3px;
    border: none;
    width: 30px;
    height: 30px;
    cursor: pointer; }
  .btn-color-active > button:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px; }

.tooltipOrders {
  cursor: help;
  display: block;
  position: relative;
  color: #FFFFFF !important;
  font-size: 15px;
  width: 150px;
  height: 40px;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 500;
  padding: 8px 10px 0 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center; }
  .tooltipOrders > span {
    position: absolute;
    text-align: center;
    visibility: hidden;
    right: 0;
    background-color: #000000;
    color: #ffffff !important;
    border-radius: 5px;
    padding: 5px 10px; }
  .tooltipOrders span:after {
    content: '';
    position: absolute;
    top: 100%;
    right: 10%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid #000000;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent; }

.tooltipOrders:hover span {
  visibility: visible;
  opacity: 0.9;
  bottom: 40px;
  right: 0;
  z-index: 999; }

.filter-order select {
  width: 300px; }

@media (max-width: 992px) {
  .filter-order select {
    width: 100%; } }

.product-card-list {
  border-radius: 3px;
  height: 125px;
  width: 85px;
  margin: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 0;
  position: relative;
  padding: 3px; }
  .product-card-list-title {
    margin-bottom: -5px; }
  .product-card-list-subtitle {
    color: #00BFB5;
    font-size: 10px; }
  .product-card-list .bottom-tags {
    font-size: 10px;
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
    text-align: center; }
    .product-card-list .bottom-tags .product-tag {
      height: 18px;
      width: 79px;
      border-radius: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 2px 5px;
      margin-top: 3px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center; }
  .product-card-list .top-tags {
    font-size: 10px;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 5px;
    margin-top: 5px; }
    .product-card-list .top-tags .product-tag {
      height: 18px;
      width: auto;
      border-radius: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 2px 5px; }

.product-card-detail {
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover; }

.details-tags {
  font-size: 10px;
  border-radius: 3px;
  width: 95px;
  text-align: center;
  padding: 2px 5px;
  height: 18px;
  line-height: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.tag-discount {
  font-size: 11px;
  height: 20px;
  width: auto;
  border-radius: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 2px 5px; }

.collection-color {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .collection-color-button {
    background-color: #F2F4F4;
    width: 130px;
    height: 40px;
    border-radius: 3px;
    cursor: pointer; }
  .collection-color-preview {
    margin-right: 1em;
    width: 30px;
    height: 30px;
    background: #00BFB5;
    border-radius: 3px; }
  .collection-color-text {
    flex: 0;
    margin: 0;
    text-transform: uppercase; }

.tag-preview {
  width: 174px;
  height: 30px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center; }

.suggestions {
  display: flex;
  width: 80%;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  padding: 8px;
  color: #747E8B;
  border: 1px solid #E9E9E9;
  border-radius: 4px; }

.react-calendar {
  border: none;
  width: 380px; }

.calendar-toggle::after {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0; }

.datepicker {
  display: flex;
  align-items: stretch;
  width: 590px;
  flex-wrap: wrap;
  padding: 1em 0 0 0; }
  .datepicker-options {
    flex: 1;
    height: auto;
    max-width: 180px;
    border-right: 1px solid #E9ECEC;
    font-size: .9em; }
    .datepicker-options-title {
      padding: .10rem 1.25rem;
      font-weight: bold;
      line-height: 1em;
      color: #747E8B; }
    .datepicker-options-item {
      padding: .30rem 1.25rem;
      height: 32px;
      border: none;
      border-radius: 0; }
      .datepicker-options-item:last-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; }
      .datepicker-options-item:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
  .datepicker-header-text {
    margin-top: 10px;
    text-align: center;
    display: flex;
    color: #747E8B; }
    .datepicker-header-text-from {
      border-radius: 5px 0 0  5px;
      flex: 1;
      border: 1px solid #E9ECEC;
      padding: 5px 10px;
      text-align: start;
      align-items: center;
      cursor: default !important; }
    .datepicker-header-text-to {
      border-radius: 0 5px 5px 0;
      flex: 1;
      border: 1px solid #E9ECEC;
      padding: 5px 10px;
      text-align: start;
      cursor: default !important; }
  .datepicker-calendar {
    flex: 1;
    width: 100%;
    padding: 1em; }
    .datepicker-calendar-view {
      display: flex;
      flex: 0 1 100%;
      position: relative; }
  .datepicker-footer {
    display: flex;
    flex: 0 1 100%;
    padding: 30px 10px 0 10px;
    justify-content: flex-end; }

.react-calendar__tile--active {
  background-color: #9983e8 !important;
  color: #ffffff !important;
  border-radius: 5px; }

.react-calendar__tile.react-calendar__month-view__days__day {
  height: 35px;
  max-width: 13% !important;
  padding: 0;
  margin: 2.3px !important; }

.react-calendar__tile--rangeStart {
  background-color: #6D4AED !important; }

.react-calendar__tile--rangeEnd {
  background-color: #6D4AED !important; }

.react-calendar__tile--hasActive {
  background-color: #6D4AED !important;
  color: #ffffff !important;
  border-radius: 5px; }

.list-group-item.active {
  background-color: #6D4AED; }

.react-calendar__tile {
  background-color: #ffffff;
  border: none; }

.react-calendar__tile:focus {
  outline: thin dotted;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: 0px; }

.react-calendar__tile--now {
  background: #ffffff;
  color: #00BFB5; }

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
  background-color: #F5F5F5 !important;
  color: black !important; }

.react-calendar__navigation button[disabled] {
  background-color: transparent; }

.block-navigation {
  width: 380px;
  height: 200px;
  z-index: 100;
  position: fixed;
  bottom: 80px; }

.react-calendar__tile.react-calendar__year-view__months__month {
  height: 80px; }

.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
  background-color: #6D4AED; }

.react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus {
  background: #6D4AED;
  color: #ffffff;
  border-radius: 5px; }

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
  background: #6D4AED;
  color: #ffffff;
  border-radius: 5px; }

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  border-radius: 5px; }

.react-calendar__navigation__arrow {
  background-color: #ffffff;
  border: none;
  width: 2em;
  height: 3em;
  cursor: pointer; }

.react-calendar__navigation__arrow:focus {
  outline: thin dotted;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: 0px; }

.react-calendar__navigation__label {
  border: none;
  height: 3em; }

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none; }

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none; }

.react-calendar__navigation__label {
  font-weight: bold;
  font-size: .9em;
  color: #8D8D8D;
  width: 20px; }

.react-calendar__navigation__label::first-letter {
  text-transform: capitalize; }

.react-calendar__month-view__weekdays__weekday {
  text-decoration: none;
  color: #8D8D8D;
  text-align: center; }

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important; }

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--hover {
  background-color: #ffffff; }

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--hover.react-calendar__tile--hoverEnd {
  background-color: #F5F5F5; }

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: #F5F5F5; }

.app-editor-sidnav-itens {
  border: none;
  height: 50px;
  color: #747E8B;
  font-weight: bold;
  font-size: 12px;
  font-style: normal; }

.app-editor-dragndrop {
  border: 1px solid #747E8B;
  border-radius: 5px;
  height: 100%;
  padding: 5px 17px 5px 25px;
  color: #747E8B;
  background: white; }
  .app-editor-dragndrop > button {
    color: #747E8B; }
  .app-editor-dragndrop-list::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F2F4F4; }
  .app-editor-dragndrop-list::-webkit-scrollbar {
    width: 6px;
    background-color: #999; }
  .app-editor-dragndrop-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #999; }

.app-editor-orientation {
  display: block; }
  .app-editor-orientation-button {
    background-color: #c4c4c4;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .app-editor-orientation-button-landscape {
      width: 32px;
      height: 24px;
      background: #ffffff;
      border-radius: 4px;
      z-index: 1; }
    .app-editor-orientation-button-portrait {
      width: 24px;
      height: 36px;
      background: #ffffff;
      border-radius: 4px;
      z-index: 1; }
    .app-editor-orientation-button-square {
      width: 24px;
      height: 24px;
      background: #ffffff;
      border-radius: 4px;
      z-index: 1; }
    .app-editor-orientation-button-stripe {
      width: 32px;
      height: 15px;
      background: #ffffff;
      border-radius: 4px;
      z-index: 1; }
  .app-editor-orientation-text {
    color: #c4c4c4; }

.orientation-active .app-editor-orientation-button {
  background-color: #6d4aed; }

.orientation-active .app-editor-orientation-text {
  color: #6d4aed; }

.cel-preview {
  height: 537px;
  overflow-x: auto;
  padding: 0; }

.edit-plugin {
  position: fixed;
  top: 0;
  right: -450px;
  height: 100vh;
  background-color: #ffffff;
  z-index: 2;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  padding: 24px;
  min-width: 450px;
  max-width: 450px;
  transition-duration: 0.5s;
  overflow-x: hidden;
  overflow-y: scroll; }
  .edit-plugin::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F2F4F4; }
  .edit-plugin::-webkit-scrollbar {
    width: 6px;
    background-color: #999; }
  .edit-plugin::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #999; }

.edit-plugin.active-plugin {
  transition-duration: 0.5s;
  transform: translate(-100%); }

.assistant-shipping {
  margin: 0;
  padding: 0; }
  .assistant-shipping .timeline {
    color: #BCBEC0;
    height: 50px;
    display: flex; }
    .assistant-shipping .timeline ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      align-content: center;
      overflow: hidden;
      text-transform: uppercase; }
      .assistant-shipping .timeline ul::before {
        width: 70%;
        height: 3px;
        background: #BCBEC0;
        content: "";
        display: block;
        position: absolute;
        top: 105px; }
      .assistant-shipping .timeline ul li {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        align-self: center;
        transition: .4s; }
        .assistant-shipping .timeline ul li button {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: #ffffff;
          border: 3px solid #BCBEC0;
          display: flex;
          align-items: center;
          cursor: pointer;
          z-index: 1;
          outline: 0 !important; }
          .assistant-shipping .timeline ul li button.checked {
            background-color: #6D4AED;
            border-color: #6D4AED; }
        .assistant-shipping .timeline ul li button:focus {
          outline: 0px auto -webkit-focus-ring-color;
          outline-offset: 0px; }
        .assistant-shipping .timeline ul li span {
          margin-top: 10px;
          display: flex;
          justify-content: center; }
        .assistant-shipping .timeline ul li.completed::before {
          border: 5px solid #6D4AED;
          background-color: #6D4AED; }
        .assistant-shipping .timeline ul li.completed::after {
          width: 100%;
          height: 3px;
          position: relative;
          left: 120px;
          bottom: 43px;
          background-color: #6D4AED;
          content: ""; }
        .assistant-shipping .timeline ul li.completed span {
          color: #6D4AED;
          font-weight: bold; }

@media (max-width: 990px) {
  .assistant-shipping .timeline ul::before {
    top: 103px; }
  .assistant-shipping .timeline ul li span {
    font-size: 10px;
    text-align: center;
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: center; }
  .assistant-shipping .timeline ul li.completed::after {
    width: 100%;
    height: 3px;
    position: relative;
    left: 67px;
    bottom: 62px;
    background: #6D4AED;
    content: ""; } }

@media (max-width: 500px) {
  .assistant-shipping .timeline ul::before {
    top: 103px; }
  .assistant-shipping .timeline ul li span {
    font-size: 10px;
    text-align: center;
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: center; }
  .assistant-shipping .timeline ul li.completed::after {
    width: 100%;
    height: 3px;
    position: relative;
    left: 50px;
    bottom: 62px;
    background: #6D4AED;
    content: ""; } }

.content-freight {
  height: 200px !important; }

.text-freight {
  text-align: center;
  color: #747E8B;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: 19px;
  margin: 0; }

/* area RulesShipping */
.content-role-freight {
  display: flex;
  flex: row;
  margin-bottom: 30px;
  border: 1px solid #E9E9E9;
  border-radius: 4px;
  box-sizing: border-box; }
  .content-role-freight .box-left {
    width: 520px;
    border-right: 1px solid #E9E9E9;
    padding: 20px; }
  .content-role-freight .box-right {
    width: 520px;
    padding: 20px; }

.new-condition-freight {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #E9E9E9; }
  .new-condition-freight > button {
    background-color: #ffffff;
    border: none;
    cursor: pointer; }
  .new-condition-freight > button:hover {
    color: #FF5B5C; }

@media only screen and (max-width: 960px) {
  .content-role-freight .box-left {
    width: 520px;
    border-right: 1px solid #E9E9E9;
    padding: 20px; }
  .content-role-freight .box-right {
    width: 520px;
    padding: 20px; } }

.toast-push {
  background-color: #ffffff;
  z-index: 1;
  width: 300px;
  position: absolute;
  bottom: 5%;
  right: 1%;
  animation-duration: 5s;
  animation: go-back 1s; }
  .toast-push .toast-push-header {
    background-color: #6CD691;
    border-radius: 5px 5px 0 0;
    color: #ffffff;
    padding: 2px 10px;
    margin: 0;
    border-bottom: 1px solid #E9E9E9;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center; }
    .toast-push .toast-push-header > button {
      color: #ffffff; }
    .toast-push .toast-push-header > img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #ffffff; }
    .toast-push .toast-push-header > span {
      display: flex;
      padding-bottom: 10px;
      margin: 0;
      color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 17px; }
  .toast-push .toast-push-body {
    padding: 15px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-radius: 0 0 5px 5px; }

@keyframes go-back {
  0% {
    transform: translateX(100px);
    overflow-x: hidden; }
  100% {
    transform: translateX(0);
    overflow-x: hidden; } }

.push-order {
  color: #ffffff;
  background: #6CD691;
  border: none;
  cursor: pointer;
  text-align: left; }

.push-order:focus {
  outline: thin dotted;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: 0px; }

input[type="range"] {
  outline: 0;
  border: 0;
  border-radius: 500px;
  min-width: 100%;
  cursor: pointer;
  margin: 15px 0 11px;
  transition: box-shadow 0.2s ease-in-out; }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="range"] {
      overflow: hidden;
      height: 10px;
      -webkit-appearance: none;
      background-color: #ddd; }
    input[type="range"]::-webkit-slider-runnable-track {
      height: 5px;
      -webkit-appearance: none;
      color: #444;
      transition: box-shadow 0.2s ease-in-out; }
    input[type="range"]::-webkit-slider-thumb {
      width: 10px;
      -webkit-appearance: none;
      height: 10px;
      background: #fff;
      box-shadow: -340px 0 0 335px #6d4aed,  inset 0 0 0 2px #6d4aed;
      border-radius: 50%;
      transition: box-shadow 0.2s ease-in-out;
      position: relative;
      top: -8px; }
    input[type="range"]:active::-webkit-slider-thumb {
      background: #fff; } }
  input[type="range"]::-moz-range-progress {
    background-color: #43e5f7; }
  input[type="range"]::-moz-range-track {
    background-color: #9a905d; }
  input[type="range"]::-ms-fill-lower {
    background-color: #43e5f7; }
  input[type="range"]::-ms-fill-upper {
    background-color: #9a905d; }

input[type="range"]:disabled {
  outline: 0;
  border: 0;
  border-radius: 500px;
  min-width: 100%;
  cursor: pointer;
  margin: 15px 0 11px;
  transition: box-shadow 0.2s ease-in-out; }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="range"]:disabled {
      overflow: hidden;
      height: 10px;
      -webkit-appearance: none; }
    input[type="range"]:disabled::-webkit-slider-runnable-track {
      height: 5px;
      -webkit-appearance: none;
      color: #C4C4C4; }
    input[type="range"]:disabled::-webkit-slider-thumb {
      width: 10px;
      -webkit-appearance: none;
      height: 10px;
      background: #fff;
      box-shadow: -340px 0 0 335px #C4C4C4;
      border-radius: 50%;
      position: relative;
      top: -8px; }
    input[type="range"]:disabled:disabled::-webkit-slider-thumb {
      background: #fff;
      box-shadow: -340px 0 0 335px #C4C4C4, inset 0 0 0 2px #C4C4C4; } }
  input[type="range"]:disabled::-moz-range-progress {
    background-color: #43e5f7; }
  input[type="range"]:disabled::-moz-range-track {
    background-color: #9a905d; }
  input[type="range"]:disabled::-ms-fill-lower {
    background-color: #43e5f7; }
  input[type="range"]:disabled::-ms-fill-upper {
    background-color: #9a905d; }

input:not(:active) + #h4-container h4 {
  opacity: 0;
  pointer-events: none; }

.toast {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 1em 0;
  padding: 10px;
  color: #ffffff; }
  .toast-content {
    display: flex;
    align-items: center; }
  .toast-close {
    display: flex;
    align-items: center;
    color: #ffffff;
    text-decoration: none; }
    .toast-close:hover {
      color: #ffffff;
      text-decoration: none; }
    .toast-close:active {
      color: #ffffff;
      text-decoration: none; }
    .toast-close:focus {
      color: #ffffff;
      text-decoration: none; }

.successes {
  border: 2px solid #6CD691;
  background: #6CD691; }

.error {
  border: 2px solid #FF5B5C;
  background: #FF5B5C; }

.stores-container {
  display: flex;
  flex-direction: row; }
  .stores-container .stores.search {
    width: 100%; }
  .stores-container .stores-filter {
    display: flex;
    justify-content: space-between; }
    .stores-container .stores-filter div {
      width: 100%; }
      .stores-container .stores-filter div select {
        width: 100% !important; }
    .stores-container .stores-filter div:first-child {
      margin-right: 10px; }

@media only screen and (max-width: 1200px) {
  .stores-container .stores-filter {
    display: flex;
    justify-content: space-between; }
    .stores-container .stores-filter select {
      width: 100%; } }

@media only screen and (max-width: 765px) {
  .stores-container {
    display: flex;
    flex-direction: column; } }

.pagination-nav {
  display: flex;
  align-items: center;
  padding: 20px;
  height: 80px; }

.pagination-rows {
  display: flex;
  align-items: center; }
  .pagination-rows-span {
    margin: 0 4px;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #747E8B; }
  .pagination-rows-select-area {
    width: 80px;
    height: 32px;
    margin: 0 4px;
    border: 2px solid #E9E9E9;
    border-radius: 3px; }
  .pagination-rows-select-box {
    cursor: pointer;
    height: 100%;
    width: 100%;
    outline: none;
    border-color: transparent;
    border-width: 0px 8px;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #747E8B; }

.pagination-divider {
  height: 32px;
  margin: 0px 12px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  color: #BCBEC0; }

.pagination-range {
  margin-left: 4px;
  margin-right: auto;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #747E8B; }

.pagination-page-list {
  display: flex;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0; }
  .pagination-page-list-change-button {
    display: block;
    position: relative;
    height: 24px;
    width: 24px;
    margin: 0 6px;
    padding: 0;
    border: none;
    background-color: transparent;
    color: #BCBEC0; }
    .pagination-page-list-change-button:not(.disabled) {
      color: #00BFB5;
      cursor: pointer;
      outline: none; }
  .pagination-page-list-button {
    user-select: none;
    border: none;
    border-radius: 3px;
    height: 32px;
    width: 32px;
    margin: 0 2px;
    padding: 0;
    background-color: transparent;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #747E8B; }
    .pagination-page-list-button:not(.disabled) {
      cursor: pointer;
      outline: none; }
    .pagination-page-list-button.current-page {
      background-color: #00BFB5;
      color: #ffffff; }

.pagination-go-to {
  display: flex;
  align-items: center; }
  .pagination-go-to span {
    width: 88px;
    margin-left: 18px;
    margin-right: 4px;
    color: #747E8B;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px; }
  .pagination-go-to-box {
    display: flex;
    align-items: center;
    margin: 0 4px;
    border: 2px solid #E9E9E9;
    border-radius: 3px; }
    .pagination-go-to-box .go-to-input {
      width: 32px;
      margin: 5px 0 4px 8px;
      padding: 0;
      border: none;
      outline: none;
      font-family: "Open Sans", sans-serif;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #707070; }
      .pagination-go-to-box .go-to-input.disabled {
        cursor: default;
        color: #BCBEC0; }
    .pagination-go-to-box .go-to-button {
      height: 24px;
      margin: 2px;
      padding: 0;
      border: none;
      background-color: transparent;
      color: #DBDBDB;
      outline: none; }
      .pagination-go-to-box .go-to-button:not(.disabled) {
        cursor: pointer;
        color: #747E8B; }

.products-container {
  max-height: 55vh;
  display: flex;
  flex-direction: column; }

.products-autocomplete-row {
  padding-right: 16px;
  justify-content: space-between; }

.alert-products {
  display: flex;
  justify-content: space-between; }
  .alert-products-align {
    display: inline-flex;
    align-items: center; }
  .alert-products-span {
    margin-left: 16px; }

.products-list {
  display: inline-block;
  width: 100%;
  overflow: auto; }
  .products-list::-webkit-scrollbar {
    width: 6px;
    background-color: #F2F4F4; }
  .products-list::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F2F4F4; }
  .products-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #999; }
  .products-list .product-th-name {
    width: 64%; }

.product-modal-footer {
  width: 100%;
  padding-right: 16px; }
  .product-modal-footer button {
    width: 190px; }

.categories-list {
  max-height: 400px;
  overflow-x: auto;
  scrollbar-color: #999 #F2F4F4;
  scrollbar-width: thin; }
  .categories-list::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F2F4F4; }
  .categories-list::-webkit-scrollbar {
    width: 6px;
    background-color: #999; }
  .categories-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #999; }

/* Auto setup import files */
.stepper {
  position: fixed;
  z-index: 100;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #ffffff;
  padding: 10px 0;
  border-top: 1px solid #F5F5F5; }
  .stepper .container {
    position: relative; }
  .stepper .skip-step {
    position: absolute;
    bottom: 60px;
    right: 15px;
    color: #8D8D8D; }
    .stepper .skip-step:hover {
      color: #8D8D8D; }
  .stepper .timeline {
    height: 40px;
    display: flex;
    position: relative; }
    .stepper .timeline .btn-light, .stepper .timeline .btn-primary {
      width: 100px; }
    .stepper .timeline ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      width: calc(100% - 200px);
      position: relative;
      overflow: hidden; }
      .stepper .timeline ul::before {
        width: 100%;
        height: 3px;
        background: #F5F5F5;
        content: "";
        display: block;
        position: absolute;
        top: 7px; }
      .stepper .timeline ul li {
        font-size: 12px;
        text-align: center;
        flex: 1;
        position: relative; }
        .stepper .timeline ul li span {
          color: #8D8D8D;
          display: block;
          margin-top: 20px; }
        .stepper .timeline ul li::before {
          position: absolute;
          width: 18px;
          height: 18px;
          border-radius: 9px;
          display: block;
          content: "";
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          border: 2px solid #E9E9E9;
          background: #ffffff; }
        .stepper .timeline ul li.completed::before {
          border: 2px solid #00BFB5;
          background: #00BFB5; }
        .stepper .timeline ul li.completed::after {
          width: 100%;
          height: 3px;
          background: #00BFB5;
          content: "";
          display: block;
          position: absolute;
          top: 7px; }
        .stepper .timeline ul li.completed span {
          color: #00BFB5;
          font-weight: bold; }
        .stepper .timeline ul li.active::before {
          border: 2px solid #E9E9E9;
          background: #6D4AED;
          z-index: 2; }
        .stepper .timeline ul li.active::after {
          width: 50%;
          height: 3px;
          background: #00BFB5;
          content: "";
          display: block;
          position: absolute;
          top: 7px;
          z-index: 1; }
        .stepper .timeline ul li.active span {
          color: #6D4AED;
          font-weight: bold; }

.material-card {
  background: #ffffff;
  cursor: pointer;
  border-color: #EEEEEE;
  height: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  text-align: left;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 5px; }
  .material-card .icon {
    margin-right: 15px; }
  .material-card:hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px; }
  .material-card i {
    background-color: #E9E9E9;
    color: #80868B;
    padding: 10px;
    border-radius: 5px; }

.card-title {
  font-size: 16px;
  font-weight: 700;
  color: #4B5F79; }

.card-colorpicker {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  height: 150px;
  width: 145px;
  margin: 10px auto;
  position: relative;
  cursor: pointer;
  overflow: hidden; }
  .card-colorpicker .color-name {
    position: absolute;
    bottom: 40px;
    width: 100%;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center; }
  .card-colorpicker .color-label {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: #ffffff;
    text-align: center;
    padding: 6px;
    font-weight: 700; }

.card-logo {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  height: 150px;
  width: 300px;
  margin: 10px auto;
  position: relative;
  cursor: pointer; }
  .card-logo .logo-upload .box-img {
    border-radius: 5px 5px 0 0; }
  .card-logo .logo-label {
    position: absolute;
    bottom: 2px;
    width: 100%;
    background: #ffffff;
    text-align: center;
    padding: 6px;
    font-weight: 700; }

.baixe-modelo {
  text-align: right;
  width: 77%;
  margin: auto;
  margin-top: 10px;
  color: #8D8D8D; }
  .baixe-modelo i {
    font-size: 12px; }

.splash-preview {
  width: 300px;
  height: 480px;
  margin: auto;
  background-color: #ffffff; }

.logo-splash {
  height: 480px;
  margin-left: 15px;
  width: 270px;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 10px;
  background-position: center;
  background-position-x: center;
  background-position-y: center; }

.screen-splash {
  height: 480px;
  width: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-position-x: center;
  background-position-y: center; }

.stores {
  width: 100%;
  height: 107ch;
  overflow-y: scroll; }

.empty {
  margin: auto;
  padding: 5%;
  width: 100%; }

.buttons-outlet {
  margin-top: 26px; }

.add-appstore-button {
  background-color: #00BFB5;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 12px;
  margin-left: -25px;
  margin-top: 10px;
  padding-left: 34px;
  padding-right: 34px;
  cursor: pointer; }
  .add-appstore-button:hover {
    opacity: 0.8; }

.add-outlet-button {
  background-color: #00BFB5;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 12px;
  padding-left: 34px;
  padding-right: 34px;
  margin-left: 20px;
  cursor: pointer; }
  .add-outlet-button:hover {
    opacity: 0.8; }

.download-model {
  position: absolute;
  bottom: -30px;
  margin-left: -19%;
  font-size: 13px;
  color: #666; }

.table-crud {
  text-transform: capitalize; }
  .table-crud th {
    border: none;
    font-weight: 300;
    color: #B1B1B1; }

.btn-group-crud {
  color: #424242;
  border: 1px solid #EEEEEE; }

.second {
  margin-top: 50px; }

.horarios {
  margin-left: 20px; }

@font-face {
  font-family: Blokk;
  src: url("/fonts/BLOKK.otf"); }

.preview {
  width: 300px;
  margin: auto;
  background-color: #ffffff; }

.barra {
  height: 70px;
  width: 300px; }

.banner-app {
  width: 300px;
  height: 172px;
  background-color: #F5F5F5; }

.banner-bot {
  width: 300px;
  height: 172px;
  background-color: #ffffff; }

.banner-app-2 {
  height: 147px;
  width: 277px;
  border-radius: 3px;
  background-color: #F5F5F5;
  margin: auto;
  margin-top: 14px;
  margin-bottom: 26px; }

.rectangle-copy {
  height: 58px;
  width: 277px;
  border-radius: 3px;
  background-color: #F5F5F5;
  margin: auto;
  margin-top: 14px; }

.footer-app {
  padding: 3%;
  height: 50px;
  width: 300px;
  margin: auto;
  background-color: #F5F5F5;
  box-shadow: 0 -1px 0 0 #E7E7E7; }

.brand-colors {
  width: 100%;
  height: 100ch; }

.preview .preview-app, .preview .preview-email, .preview .preview-shopbot {
  border: 1px solid #E7E7E7;
  overflow: hidden; }

.block-font {
  font-family: Blokk; }

.text-rect {
  margin-top: -4%;
  margin-left: -4%; }

.tab-preview {
  width: 330px;
  margin: auto;
  margin-bottom: 28px;
  background-color: #ffffff;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  padding: 5px; }

.tab-preview2 {
  width: 355px;
  margin: auto;
  margin-bottom: 28px;
  background-color: #ffffff;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  padding: 5px; }

.chat {
  height: 46px;
  width: 197px;
  border-radius: 5px;
  background-color: #F5F5F5;
  margin-left: 15px;
  margin-bottom: 15px; }

.chat-right {
  margin-left: 92px; }

.footer-shopbot {
  height: 46px;
  width: 300px;
  background-color: #ffffff;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.09); }

.send-icon {
  margin-top: 10px;
  margin-right: 10px; }

.email {
  height: 78px;
  width: 78px;
  background-color: #F5F5F5;
  border-radius: 78px;
  margin: auto;
  margin-top: 30px; }

.rectangle-email {
  height: 13px;
  width: 160px;
  border-radius: 3px;
  background-color: #F5F5F5;
  margin: auto;
  margin-top: 12px; }

.rectangle-email2 {
  height: 13px;
  width: 232px;
  border-radius: 3px;
  background-color: #F5F5F5;
  margin: auto;
  margin-top: 12px; }

.nav-link.active-tab {
  background-color: #00BFB5;
  color: #ffffff; }

.nav-link {
  color: #5A5E61; }

.logos {
  width: 100%;
  height: 100ch; }

.logo-upload {
  position: relative; }
  .logo-upload .box-img {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer; }
  .logo-upload .close-img {
    position: absolute;
    top: -10px;
    right: -10px;
    border: none;
    background-color: #ffffff;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    border: 1px solid #EFEFEF; }
    .logo-upload .close-img:hover {
      opacity: 1; }
    .logo-upload .close-img:before, .logo-upload .close-img:after {
      position: absolute;
      content: ' ';
      height: 10px;
      padding: -8px;
      width: 2px;
      margin-top: -5px;
      margin-left: -1px;
      background-color: #BBBDC0; }
    .logo-upload .close-img:before {
      transform: rotate(45deg); }
    .logo-upload .close-img:after {
      transform: rotate(-45deg); }
  .logo-upload .close-img-carrousel {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background-color: #FF5B5C;
    color: #ffffff;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer; }
    .logo-upload .close-img-carrousel:hover {
      opacity: 1; }
    .logo-upload .close-img-carrousel:before, .logo-upload .close-img-carrousel:after {
      position: absolute;
      content: ' ';
      height: 10px;
      padding: -8px;
      width: 2px;
      margin-top: -5px;
      margin-left: -1px;
      background-color: #ffffff; }
    .logo-upload .close-img-carrousel:before {
      transform: rotate(45deg); }
    .logo-upload .close-img-carrousel:after {
      transform: rotate(-45deg); }

.text-logo {
  padding-top: 1.5%; }

.text-icon-logo {
  padding-top: 10%; }

.welcome {
  text-align: center;
  height: 100ch;
  overflow: hidden; }
  .welcome p {
    width: 69%;
    margin: auto;
    margin-top: 30px;
    font-size: 18px; }

.lori > img {
  width: 250px; }

.lori.confirm > img {
  width: 400px; }

.materials {
  text-align: center;
  width: 100%;
  height: 100ch; }
  .materials h1 {
    font-weight: 200; }

.materials-card {
  cursor: pointer; }
  .materials-card:hover {
    border-bottom: 3px solid #00BFB5;
    margin-bottom: -3px; }
  .materials-card i {
    background-color: #E9E9E9;
    color: #80868B;
    padding: 10px;
    border-radius: 5px; }

.card-title {
  font-size: 16px;
  font-weight: 700; }

.basic-info {
  width: 100%;
  height: 100ch; }

.app-store {
  width: 100%;
  height: 100ch; }
  .app-store .card-store {
    background-color: #ffffff;
    border: 1px solid #E9E9E9;
    margin: auto;
    margin-bottom: 15px;
    padding: 15px;
    font-size: 14px;
    width: 530px;
    color: #434343;
    border-radius: 5px; }
    .app-store .card-store a {
      color: #6D4AED; }
    .app-store .card-store h4 {
      font-size: 16px;
      font-weight: 700; }
    .app-store .card-store p {
      margin-bottom: 0; }

.number-store {
  font-size: 30px;
  margin: auto;
  padding: auto;
  padding-left: 35px;
  font-weight: 800;
  text-align: center; }

.email-app {
  font-weight: 700; }

.terms-of-use {
  width: 100%;
  height: 100ch; }

/* Panel import files */
.import-matcher {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  border-radius: 7px;
  border: 1px solid #dbdbdb;
  padding: 1.15em;
  align-items: center; }
  .import-matcher-icon {
    display: flex;
    min-height: 48px;
    min-width: 48px;
    background: #9a76f3;
    border-radius: 48px;
    justify-content: center;
    align-items: center; }
    .import-matcher-icon .material-icons {
      height: 24px;
      color: #fff; }
  .import-matcher-description {
    line-height: 10px;
    margin-left: 16px; }
    .import-matcher-description .link {
      color: #6d4aed;
      text-decoration: none !important;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px; }
    .import-matcher-description p {
      display: flex;
      align-items: center;
      font-weight: bold;
      line-height: 19px;
      margin-bottom: 3px; }
  .import-matcher-upload {
    flex: 1;
    display: flex;
    justify-content: end; }
    .import-matcher-upload .btn {
      width: 190px; }
      .import-matcher-upload .btn input[type="file"] {
        display: none; }

.import-status-titles {
  font-size: 18pxs; }

@media screen and (max-width: 992px) {
  .mobile-space {
    margin-top: 1em; } }

.tooltipNeoMatcher {
  cursor: help;
  display: flex;
  position: relative;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center; }
  .tooltipNeoMatcher i {
    font-size: 16px; }
  .tooltipNeoMatcher > span {
    position: absolute;
    text-align: center;
    visibility: hidden;
    white-space: normal;
    right: 0;
    width: 360px;
    line-height: 16px;
    background-color: #000000;
    color: #ffffff !important;
    border-radius: 5px;
    padding: 5px 10px; }
  .tooltipNeoMatcher span:after {
    content: "";
    position: absolute;
    top: 100%;
    right: 45%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid #000000;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent; }

.tooltipNeoMatcher:hover span {
  visibility: visible;
  opacity: 0.9;
  bottom: 33px;
  right: 0;
  z-index: 999;
  right: -157px; }
