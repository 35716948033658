.card {
  box-shadow: 0px 10px 14px 0 rgba(25, 42, 70, 0.10);
  border: 0;

  .card-header {
    padding: 1.4rem;
    background-color: map-get($theme-colors, "link");
    position: relative;
    border-bottom: 0;
    //display: flex;

    .card-title {
      margin: 0;
      font-weight: bold;
      font-size: 24px;
      color: map-get($theme-colors, "titles");
    }
  }
  .card-body {
      padding: 1.4rem;
      &.no-padding {
          padding:0;
      }

  }
}

