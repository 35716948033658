.form-group {
	&.icon-right {
		i {
			position: absolute;
			top: 1.55em;
			right: 20px;
			color: map-get($theme-colors, "grey-50");
		}
		&-error {
			i {
				position: absolute;
				top: 1.55em;
				right: 23px;
				color: map-get($theme-colors, "danger");
			}
			&-select {
				i {
					position: absolute;
					top: 1.55em;
					right: 50px;
					color: map-get($theme-colors, "danger");
				}
			}
		}
	}
}

.form-control {
	border: 2px solid map-get($theme-colors, "grey-20");
	color: map-get($theme-colors, "titles");
	font-size: 14px;
	&:active,
	&:focus {
		border-color: map-get($theme-colors, "secondary");
		box-shadow: 0 0 0 0.2rem rgba(map-get($theme-colors, "secondary"), 0.25);
		color: map-get($theme-colors, "titles");
	}
	&::placeholder {
		color: rgba(map-get($theme-colors, "titles"), 0.4);
	}
}

.input-group {
	.input-group-text {
		color: map-get($theme-colors, "titles");
		background: map-get($theme-colors, "grey-20");
		border: 0;
	}
}

.custom-select {
	border: 2px solid map-get($theme-colors, "grey-20");
}

.custom-select-neo {
	width: 100%;
	height: 40px;
	padding: 5px 10px;
	border: 2px solid map-get($theme-colors, "grey-20");
	color: map-get($theme-colors, "icons");
	border-radius: 4px;

	&:active,
	&:focus {
		border-color: map-get($theme-colors, "secondary");
		box-shadow: 0 0 0 0.2rem rgba(map-get($theme-colors, "secondary"), 0.25);
		color: map-get($theme-colors, "icons");
		outline: 0;
	}
	&::placeholder {
		color: rgba(map-get($theme-colors, "icons"), 0.4);
	}
}

.custom-checkbox {
	cursor: pointer;
	.custom-control-label {
		&::before,
		&::after {
			cursor: pointer;
			top: 50%;
			width: 17px;
			height: 17px;
			transform: translateY(-50%);
		}
		&::before {
			border: 2px solid map-get($theme-colors, "grey-20");
			background-color: map-get($theme-colors, "white");
		}
	}
	.custom-control-input {
		&:checked ~ .custom-control-label::before {
			background: map-get($theme-colors, "primary");
			border-color: map-get($theme-colors, "primary");
		}
	}
}

.custom-radio-input {
	margin: 25px 0 !important;

	padding: 0 !important;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 1px solid #f1f1f2;

	input[type="radio"] {
		display: none;
	}

	input[type="radio"] + label span {
		display: inline-block;
		width: 16px;
		height: 16px;
		vertical-align: middle;
		margin: -1px 10px 0 0;
		border-radius: 50%;
		border: 2px double #bcbec0;
		//background: map-get($theme-colors, "success");
		cursor: pointer;
	}

	input[type="radio"]:checked + label span {
		border: 6px double #ffff;
		width: 20px;
		height: 20px;
		margin: 0 8px -2px -2px;
		background-color: map-get($theme-colors, "primary");
	}
}

.autocomplete {
	position: relative;
	.search {
		width: 100%;
		&:focus {
			border-color: map-get($theme-colors, "grey-20");
			box-shadow: none;
		}
	}
	&.icon-right {
		i {
			position: absolute;
			top: 8px;
			right: 8px;
			color: map-get($theme-colors, "grey-50");
		}
	}
	.result {
		width: 100%;
		max-height: 400px;
		margin-top: -2px;
		overflow-y: scroll;
		position: absolute;
		background-color: map-get($theme-colors, "white");
		padding: 0;
		border: 2px solid map-get($theme-colors, "grey-20");
		border-radius: 0px 0px 5px 5px;
		z-index: 999;
		ul {
			list-style-type: none;
			padding: 0;
		}
		li {
			border-bottom: 1px solid map-get($theme-colors, "grey-20");
			padding: 10px;
			cursor: pointer;
		}
		li:last-child {
			border-bottom: none;
		}
	}
	.neo-loading {
		position: absolute;
		right: 10px;
		width: 30px;
		height: 30px;
	}
}

.rw-multiselect {
	padding: 0;
	height: auto;
	border: 0;

	&.rw-state-focus,
	&.rw-state-focus:hover {
		.rw-widget-container {
			box-shadow: none;
			border: 2px solid map-get($theme-colors, "grey-20");
		}
	}

	.rw-widget-input {
		box-shadow: none;
		border: 2px solid map-get($theme-colors, "grey-20");
	}
	.rw-widget-picker {
		position: relative;
		> * {
			display: flex;
			align-items: center;
		}
		.rw-select {
			position: absolute;
			right: 10px;
			top: 0;
		}
		.rw-multiselect-taglist {
			padding: 0.25rem;
			padding-bottom: 0;

			.rw-multiselect-tag {
				background: map-get($theme-colors, "secondary");
				border: 0;
				color: map-get($theme-colors, "white");
				margin-top: 0;
				margin-left: 0;
				margin-right: 0.25rem;
				margin-bottom: 0.25rem;

				.rw-btn-select {
					opacity: 1;
				}
			}
		}
	}
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
	height: 300px !important;
}

.grouped-input-before {
	h6 {
		position: absolute;
		margin-top: 10px;
		margin-left: 10px;
		font-weight: 600;
		color: map-get($theme-colors, "grey-50");
		opacity: 0.8;
	}
	input {
		padding-left: 35px;
	}
}
.grouped-input-after {
	h6 {
		position: absolute;
		margin-top: -6%;
		right: 25%;
		font-weight: 600;
		color: map-get($theme-colors, "grey-50");
		opacity: 0.8;
	}
	input {
		padding-left: 35px;
	}
}
.section-step {
	margin-left: 20px;
	width: 100%;
	margin-bottom: 50px;
	.number {
		position: absolute;
		left: 20px;
		background: map-get($theme-colors, "secondary");
		width: 30px;
		height: 30px;
		font-size: 18px;
		border-radius: 50%;
		text-align: center;
		color: map-get($theme-colors, "white");
	}
	h3 {
		margin-left: 20px;
		font-weight: bold;
		font-size: 20px;
		color: map-get($theme-colors, "titles");
	}
}

.react-datepicker-popper {
	width: 330px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.react-select-container {
	> div {
		border-radius: 4px;
		border: 2px solid #dee2e6;
		&:hover {
			border: 2px solid #dee2e6;
		}
		&:focus-within {
			border: 2px solid map-get($theme-colors, "secondary");
			box-shadow: 0 0 0 0.2rem rgba(map-get($theme-colors, "secondary"), 0.25);
			color: map-get($theme-colors, "titles");
		}
		&:active {
			border: 2px solid map-get($theme-colors, "secondary");
			box-shadow: 0 0 0 0.2rem rgba(map-get($theme-colors, "secondary"), 0.25);
			color: map-get($theme-colors, "titles");
		}
	}
}
.search-plus-button {
	height: 38px;
}
