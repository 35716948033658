.react-calendar {
  border: none;
  width: 380px;
}

.calendar-toggle {
  &::after{
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
  }
}

.datepicker {
  display: flex;
  align-items: stretch;
  width: 590px;
  flex-wrap: wrap;
  padding: 1em 0 0 0;
  &-options {
    flex: 1;
    height: auto;
    max-width: 180px;
    border-right: 1px solid map-get($theme-colors, "light");
    font-size: .9em;
    &-title{
      padding: .10rem 1.25rem;
      font-weight: bold;
      line-height: 1em;
      color: map-get($theme-colors, "icons");
    }
    &-item {
      padding: .30rem 1.25rem;
      height: 32px;
      border: none;
      border-radius: 0;
      &:last-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:first-child {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
      }
    }
  }
  &-header-text{
    margin-top: 10px;
    text-align: center;
    display: flex;
    color: map-get($theme-colors, "icons");
    &-from{
      border-radius: 5px 0 0  5px;
      flex: 1;
      border: 1px solid map-get($theme-colors, "light");
      padding: 5px 10px;
      text-align: start;
      align-items: center;
      cursor: default !important;
    }
    &-to{
      border-radius: 0 5px 5px 0;
      flex: 1;
      border: 1px solid map-get($theme-colors, "light");
      padding: 5px 10px;
      text-align: start;
      cursor: default !important;
    }
  }
  &-calendar {
    flex: 1;
    width: 100%;
    padding: 1em;
    &-view{
      display: flex;
    flex:0 1 100%;
    position: relative;
    }
  }
  &-footer {
    display: flex;
    flex:0 1 100%;
    padding: 30px 10px 0 10px;
    justify-content: flex-end;
  }
}

.react-calendar__tile--active {
  background-color: #9983e8 !important;
  color:map-get($theme-colors, "white") !important;
  border-radius: 5px;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  height: 35px;
  max-width: 13% !important;
  padding: 0;
  margin: 2.3px !important;
}

.react-calendar__tile--rangeStart{
  background-color: map-get($theme-colors, "secondary") !important;
}

.react-calendar__tile--rangeEnd{
  background-color: map-get($theme-colors, "secondary") !important;
}

.react-calendar__tile--hasActive {
  background-color: map-get($theme-colors, "secondary") !important;
  color:map-get($theme-colors, "white") !important;
  border-radius: 5px;
}

.list-group-item.active{
  background-color: map-get($theme-colors, "secondary");
}
.react-calendar__tile{
  background-color:map-get($theme-colors, "white");
  border: none;
}

.react-calendar__tile:focus{
  outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
}

.react-calendar__tile--now {
  background:map-get($theme-colors, "white");
  color: map-get($theme-colors, "primary");
}

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
  background-color:map-get($theme-colors, "grey-10") !important;
  color: black !important;
}

.react-calendar__navigation button[disabled] {
  background-color:map-get($theme-colors, "link");
}

.block-navigation{
  width: 380px;
  height: 200px;
  z-index: 100;
  position: fixed;
  bottom: 80px;
}

.react-calendar__tile.react-calendar__year-view__months__month{
  height: 80px; 
}

.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__year-view__months__month{
  background-color: map-get($theme-colors, "secondary");
}

.react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus {
  background: map-get($theme-colors, "secondary");
  color:map-get($theme-colors, "white");
  border-radius: 5px;
}

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
  background: map-get($theme-colors, "secondary");
  color:map-get($theme-colors, "white");
  border-radius: 5px;
}


.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  border-radius: 5px;
}

.react-calendar__navigation__arrow {
  background-color:map-get($theme-colors, "white");
  border: none;
  width:2em;
  height: 3em;
  cursor: pointer;
}

.react-calendar__navigation__arrow:focus{
  outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
}

.react-calendar__navigation__label{
  border: none;
  height: 3em;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button{
  display: none;
}

.react-calendar__navigation__label{
  font-weight: bold;
  font-size: .9em;
  color:map-get($theme-colors, "grey-65");
  width: 20px;
}

.react-calendar__navigation__label::first-letter{
  text-transform:capitalize;
}

.react-calendar__month-view__weekdays__weekday{
  text-decoration: none;
  color:map-get($theme-colors, "grey-65");
  text-align: center;
}

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}


.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--hover{
  background-color:map-get($theme-colors, "white");
}


.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--hover.react-calendar__tile--hoverEnd {
  background-color:map-get($theme-colors, "grey-10");
}


.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus{
  background-color:map-get($theme-colors, "grey-10");
}