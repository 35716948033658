.pagination {
	&-nav {
		display: flex;
		align-items: center;
		padding: 20px;
		// justify-content: space-between;
		height: 80px;
	}

	&-rows {
		display: flex;
		align-items: center;
		&-span {
			margin: 0 4px;
			font-family: "Open Sans", sans-serif;
			font-weight: normal; // 400
			font-size: 12px;
			line-height: 16px;
			color: map-get($theme-colors, "icons");
		}

		&-select {
			&-area {
				width: 80px;
				height: 32px;
				margin: 0 4px;
				border: 2px solid map-get($theme-colors, "grey-20");
				border-radius: 3px;
			}
			&-box {
				cursor: pointer;
				height: 100%;
				width: 100%;
				outline: none;
				border-color: map-get($theme-colors, "link");
				border-width: 0px 8px;

				font-family: "Open Sans", sans-serif;
				font-weight: normal;
				font-size: 14px;
				line-height: 19px;
				color: map-get($theme-colors, "icons");
			}
		}
	}

	&-divider {
		// align-items: center;
		height: 32px;
		margin: 0px 12px;
		font-family: "Open Sans", sans-serif;
		font-size: 20px;
		color: map-get($theme-colors, "grey-50");
	}

	&-range {
		margin-left: 4px;
		margin-right: auto;

		font-family: "Open Sans", sans-serif;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		color: map-get($theme-colors, "icons");
	}

	&-page-list {
		display: flex;
		list-style: none;
		align-items: center;
		padding: 0;
		margin: 0;
		&-change-button {
			display: block;
			position: relative;
			height: 24px;
			width: 24px;
			margin: 0 6px;
			padding: 0;
			border: none;
			background-color: map-get($theme-colors, "link");
			color: map-get($theme-colors, "grey-50");

			&:not(.disabled) {
				color: map-get($theme-colors, "primary");
				cursor: pointer;
				outline: none;
			}
		}
		&-button {
			user-select: none;
			border: none;
			border-radius: 3px;
			height: 32px;
			width: 32px;
			margin: 0 2px;
			padding: 0;
			background-color: map-get($theme-colors, "link");

			font-family: "Open Sans", sans-serif;
			font-weight: bold; // 700
			font-size: 14px;
			line-height: 19px;
			color: map-get($theme-colors, "icons");

			&:not(.disabled) {
				cursor: pointer;
				outline: none;
			}
			&.current-page {
				background-color: map-get($theme-colors, "primary");
				color: map-get($theme-colors, "white");
			}
		}
	}

	&-go-to {
		display: flex;
		align-items: center;

		span {
			width: 88px;
			// margin: 0 4px;
			margin-left: 18px;
			margin-right: 4px;
			color: map-get($theme-colors, "icons");

			font-family: "Open Sans", sans-serif;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
		}
		&-box {
			display: flex;
			align-items: center;
			margin: 0 4px;
			border: 2px solid map-get($theme-colors, "grey-20");
			border-radius: 3px;

			.go-to-input {
				width: 32px;
				margin: 5px 0 4px 8px;
				padding: 0;
				border: none;
				outline: none;

				font-family: "Open Sans", sans-serif;
				font-weight: normal;
				font-size: 14px;
				line-height: 19px;
				color: map-get($theme-colors, "grey-70");

				&.disabled {
					cursor: default;
					color: map-get($theme-colors, "grey-50");
				}
			}

			.go-to-button {
				height: 24px;
				margin: 2px;
				padding: 0;
				border: none;
				background-color: map-get($theme-colors, "link");
				color: map-get($theme-colors, "grey-30");
				outline: none;

				&:not(.disabled) {
					cursor: pointer;
					color: map-get($theme-colors, "icons");
				}
			}
		}
	}
}
