.products-container {
	max-height: 55vh;
	display: flex;
	flex-direction: column;
}

.products-autocomplete-row {
	padding-right: 16px;
	justify-content: space-between;
}

.alert-products {
	display: flex;
	justify-content: space-between;
	&-align {
		display: inline-flex;
		align-items: center;
	}
	&-span {
		margin-left: 16px;
	}
}

.products-list {
	display: inline-block;
	width: 100%;
	overflow: auto;
	&::-webkit-scrollbar {
		width: 6px;
		background-color: map-get($theme-colors, "bgNeo");
	}
	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: map-get($theme-colors, "bgNeo");
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #999;
	}
	.product-th-name {
		width: 64%;
	}
}

.product-modal-footer {
	width: 100%;
	padding-right: 16px;
	button {
		width: 190px;
	}
}
