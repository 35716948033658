.importItem {
	border: 1px solid map-get($theme-colors, "grey-20");
	border-radius: 5px;
	padding: 1.4rem;

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 1.4rem;
		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			background-color: map-get($theme-colors, "primary");
			float: left;
			margin-right: 1.4rem;
			width: 50px;
			height: 50px;
			border-radius: 25px;
			color: map-get($theme-colors, "white");
		}
		.title {
			font-weight: bold;
			color: map-get($theme-colors, "titles");
			display: flex;
			align-items: center;
			.help {
				margin-left: 3px;
				height: 16px;
				color: map-get($theme-colors, "secondary");
				i {
					font-size: 16px;
				}
			}
		}
		.attachment {
			color: map-get($theme-colors, "grey-65");
			font-size: 90%;
			text-decoration: underline;
		}
	}
	.file-button {
		display: flex;
		justify-content: center;

		.btn-primary {
			input[type="file"] {
				display: none;
			}
		}
	}
}
.history-import {
	color: map-get($theme-colors, "neo-icons");

	.progress {
		margin: auto;
		background-color: map-get($theme-colors, "light");
		border-radius: 3px;
		color: map-get($theme-colors, "grey-30");
		text-transform: lowercase;
		margin-top: 10px;

		.progress-bar {
			z-index: 0;
			border-radius: 3px;
		}
		small {
			margin-left: 2px;
			position: absolute;
			margin-left: 3%;
			z-index: 0;
		}
		i {
			position: absolute;
			right: 16.5%;
			font-size: 14px;
		}
	}
}
#details {
	color: map-get($theme-colors, "neo-icons");
	.download-archive {
		position: absolute;
		margin-top: -5%;
		right: 3%;
		color: map-get($theme-colors, "titles");
		span {
			font-size: 19px;
			padding-top: 3px;
			position: absolute;
			margin-left: -21px;
			line-height: 14px;
		}
	}
	.log-erro {
		text-align: right;
		padding-right: 5px;
		i {
			font-size: 14px;
		}
		a {
			margin-left: 10px;
			color: map-get($theme-colors, "grey-30");
			font-size: 12px;
			text-decoration: underline;
		}
	}
}
.bg-primary {
	background-color: map-get($theme-colors, "primary") !important;
}
.import {
	a {
		margin-left: -14px;
		text-decoration: underline;
	}
	.import-details-body {
		margin-top: 10px;
		padding: 3%;
		border-radius: 5px;
		background: map-get($theme-colors, "light");
		font-family: "Roboto Mono", monospace;
		text-align: start;
		p {
			font-size: 14px;
			padding: 0;
			margin: 0;
			color: map-get($theme-colors, "titles");
		}
	}
}

.import-list-log-files {
	overflow-y: scroll;
	overflow-x: hidden;
	height: 30vh;
	scrollbar-color: #999 map-get($theme-colors, "bgNeo");
	scrollbar-width: thin;
	&::-webkit-scrollbar-track {
		// -webkit-box-shadow: inset 0 0 6px $secondary-color;
		border-radius: 10px;
		background-color: map-get($theme-colors, "bgNeo");
	}

	&::-webkit-scrollbar {
		width: 6px;
		background-color: #999;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		// -webkit-box-shadow: inset 0 0 6px $secondary-color;
		background-color: #999;
	}
}
