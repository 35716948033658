.sidebar {
	a {
		color: map-get($theme-colors, "icons");
		text-decoration: none;
	}
	ul {
		padding-left: 0;
	}
	li {
		list-style-type: none;
		display: flex;
		cursor: pointer;
		border-left-width: 3px;
		border-left-style: solid;
		border-left-color: map-get($theme-colors, "link");
		a {
			width: 100%;
			padding-top: 20px;
			padding-left: 20px;
			padding-bottom: 20px;
			display: flex;
			align-items: center;
		}
		button {
			background-color: map-get($theme-colors, "link");
			border: none;
			width: 100%;
			padding-top: 20px;
			padding-left: 20px;
			padding-bottom: 20px;
			display: flex;
			align-items: center;
			&:focus {
				box-shadow: 0 0 0 0;
				border: 0 none;
				outline: 0;
			}
		}
		&:hover,
		&.active {
			border-left-color: map-get($theme-colors, "primary");
			color: map-get($theme-colors, "primary");
		}
	}

	i {
		margin-right: 10px;
	}
}
.channel {
	padding: 20px;

	.active {
		border-left: 3px solid map-get($theme-colors, "primary");
		color: map-get($theme-colors, "primary");
	}
}
.csv {
	input[type='file'] {
		display: none;
	}
	cursor: pointer;
}
.upload {
	label {
		margin-bottom: 2.5px;
		cursor: pointer;
	}
}
.new-question {
	background: map-get($theme-colors, "white");
	border: 1px dashed map-get($theme-colors, "grey-20");
	box-sizing: border-box;
	border-radius: 5px;
	color: map-get($theme-colors, "titles");
	padding: 0.55rem 1rem;
	cursor: pointer;
	width: 100%;
}
.title-card {
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	color: map-get($theme-colors, "titles");
}
