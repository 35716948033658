.stores-container {
    display: flex;
    flex-direction: row;

    .stores.search {
        width: 100%;
    }

    .stores-filter {
        display: flex;
        justify-content: space-between;
        div {
            width: 100%;
            select {
                width: 100% !important;
            }
        }
        div:first-child{
            margin-right: 10px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .stores-container {
        .stores-filter {
            display: flex;
            justify-content: space-between;
    
            select {
                width: 100%;
            }
        }
    }
}
// @media only screen and (max-width: 990px) {
//     .stores-container {
//         display: flex;
//         flex-direction: column;
//         background-color: green;
//     }
// }
@media only screen and (max-width: 765px) {
    .stores-container {
        display: flex;
        flex-direction: column;
    }
}