.category {
	width: 330px;
	height: 40px;
	background: #747e8b;
	border-radius: 3px;
	color: #fff;
	padding: 16px;
	margin: 5px 0;
	&-son {
		margin: 5px 0;
		padding: 16px;
		width: 314px;
		height: 40px;
		background: #bcbec0;
		border-radius: 3px;
	}
	&-grandchild {
		margin: 5px 0;
		padding: 16px;
		width: 298px;
		height: 40px;
		background: #ececec;
		border-radius: 3px;
	}
	&-great-grandchild {
		margin: 5px 0;
		padding: 16px;
		width: 282px;
		height: 40px;
		border-radius: 3px;
		background-color: #f1f1f2;
		margin-right: 26px;
	}
	&-card {
		max-height: 820px;
		overflow: auto;
		&::-webkit-scrollbar-track {
			// -webkit-box-shadow: inset 0 0 6px $secondary-color;
			border-radius: 10px;
			background-color: map-get($theme-colors, "bgNeo");
		}

		&::-webkit-scrollbar {
			width: 0px;
			background-color: map-get($theme-colors, "bgNeo");
		}
}
.sidebar-list {
    background-color:map-get($theme-colors, "bgNeo");
    height: 100%;
    border: none;
    padding: 10px;

    .category-name {
        background-color:map-get($theme-colors, "white");
        padding-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 8px;
        border-radius: 5px;
        cursor: auto;

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			// -webkit-box-shadow: inset 0 0 6px $secondary-color;
			background-color: #999;
		}
	}
}

.add-subcategory {
	height: 18px;
	width: 18px;
	background: #00BFB5;
	opacity: 0.3;
	color: #fff;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	outline: thin dotted;
	outline: 0px auto -webkit-focus-ring-color;
	outline-offset: 0px;
	transition: 0.5s;
	&:focus {
		transition: 0.5s;
		outline: thin dotted;
		outline: 0px auto -webkit-focus-ring-color;
		outline-offset: 0px;
		background: #00bfb5;
		// opacity: 1;
	}
	&:active {
		transition: 0.5s;
		background: #00bfb5;
		// opacity: 1;
	}
	&:hover {
		transition: 0.5s;
		background: #00bfb5;
		opacity: 1;
	}
}

.drop-body{
	height: calc(20em + 20em);
}

.aux-text{
	color: #747E8B; 
	font-size: 12px
}

            i {
                cursor: pointer;
            }
            i:hover {
                color: #d3d3d3;
            }
            
            button {
                width:50px;
                background-color:map-get($theme-colors, "link");
                display: flex;
                justify-content:center;
                align-items: center;
                align-content: center;
                padding: 2px;
            }

.icon{
	background-color: map-get($theme-colors, "placeholder");
	color: map-get($theme-colors, "text-menu");
	height: 96px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}


.inserted-image {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 40px;
	border: 2px solid map-get($theme-colors, "light");
	padding: 11px;
	border-radius: 5px;
	&-infos{
		display: flex;
		// align-items: center;
		color: map-get($theme-colors, "text-menu");
	}
	&-button{
		display: flex;
		// align-items: center;
	}
}

    .new-category {
        background-color:map-get($theme-colors, "bgNeo");
        color: map-get($theme-colors, "titles");
        border: 2px dashed rgba(116, 126, 139, 0.3);
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer; 
    }
    .new-category i {
        color: map-get($theme-colors, "titles");
    }

    .new-subcategory {
        background-color: map-get($theme-colors, "bgNeo");
        color: map-get($theme-colors, "titles");
        width: 100%;
        border: 2px dashed rgba(116, 126, 139, 0.3);
        box-sizing: border-box;
    }  
}

.category-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 50px;
}
.category-body p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: map-get($theme-colors, "titles");
    width: 400px;
}
.category-title{
	font-size: 24px !important;
}