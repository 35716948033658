.app-editor {
	&-sidnav {
		&-itens {
			border: none;
			height: 50px;
			color: map-get($theme-colors, "icons");
			font-weight: bold;
			font-size: 12px;
			font-style: normal;
		}
	}

	&-dragndrop {
		border: 1px solid map-get($theme-colors, "icons");
		border-radius: 5px;
		height: 100%;
		padding: 5px 17px 5px 25px;
		color: map-get($theme-colors, "icons");
		background: white;
		> button {
			color: map-get($theme-colors, "icons");
		}
		&-list {
			&::-webkit-scrollbar-track {
				// -webkit-box-shadow: inset 0 0 6px $secondary-color;
				border-radius: 10px;
				background-color: map-get($theme-colors, "bgNeo");
			}

			&::-webkit-scrollbar {
				width: 6px;
				background-color: #999;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				// -webkit-box-shadow: inset 0 0 6px $secondary-color;
				background-color: #999;
			}
		}
	}

	&-orientation {
		display: block;
		&-button {
			background-color: #c4c4c4;
			height: 56px;
			width: 56px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			&-landscape {
				width: 32px;
				height: 24px;
				background: #ffffff;
				border-radius: 4px;
				z-index: 1;
			}
			&-portrait {
				width: 24px;
				height: 36px;
				background: #ffffff;
				border-radius: 4px;
				z-index: 1;
			}
			&-square {
				width: 24px;
				height: 24px;
				background: #ffffff;
				border-radius: 4px;
				z-index: 1;
			}
			&-stripe {
				width: 32px;
				height: 15px;
				background: #ffffff;
				border-radius: 4px;
				z-index: 1;
			}
		}
		&-text {
			color: #c4c4c4;
		}
	}
}

.orientation-active {
	.app-editor-orientation-button{
		background-color: #6d4aed;
	}
	.app-editor-orientation-text{
		color: #6d4aed;
	}
}


.cel-preview {
	height: 537px;
	overflow-x: auto;
	padding: 0;
}