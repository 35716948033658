.dragndrop-item {
    .preview-collapse {
        .content {
            width: calc(100% - 150px);
            padding-right:1.75rem;
            
            h5 {
                font-weight: bold;
                font-size: 1rem;
                color: map-get($theme-colors, "titles");
            }
            .preview-body {
                font-size: .75rem;
                color:map-get($theme-colors, "grey-65");
                p {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                
            }
        }
        .actions {
            width: 150px;
        }    
    }
}
.upload-img {
    position: relative;
    
    .box-img{
      @include card-default;
      overflow: hidden;
      cursor: pointer;  
    }
  
    .close-img {
      position: absolute;
      top: -10px;
      right:-10px;
      border: none;
      background-color:map-get($theme-colors, "white");
      border-radius: 50px;
      width: 20px;
      height: 20px;
      padding: 0;
      cursor: pointer;
      border: 1px solid #EFEFEF;
  
      &:hover {
        opacity: 1;
      }
      &:before, &:after {
        position: absolute;
        content: ' ';
        height: 10px;
        padding: -8px;
        width: 2px;
        margin-top: -5px;
        margin-left: -1px;
        background-color:map-get($theme-colors, "grey-50");
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
    .close-img-carrousel {
      position: absolute;
      top: 5px;
      right: 5px;
      border: none;
      background-color:map-get($theme-colors, "danger");
      color:map-get($theme-colors, "white");
      border-radius: 50px;
      width: 20px;
      height: 20px;
      padding: 0;
      /* opacity: 0.3; */
      cursor: pointer;
  
      &:hover {
        opacity: 1;
      }
      &:before, &:after {
        position: absolute;
        content: ' ';
        height: 10px;
        padding: -8px;
        width: 2px;
        margin-top: -5px;
        margin-left: -1px;
        background-color: map-get($theme-colors, "white");
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }