
	
	// now for the good stuff 🎉
	input[type="range"] {
		outline: 0;
		border: 0;
		border-radius: 500px;
		min-width: 100%;
    cursor: pointer;
		margin: 15px 0 11px;
		transition: box-shadow 0.2s ease-in-out;
		// Chrome
		@media screen and (-webkit-min-device-pixel-ratio:0) {
			& {
				overflow: hidden;
				height: 10px;
				-webkit-appearance: none;
				background-color: #ddd;
			}
			&::-webkit-slider-runnable-track {
				height: 5px;
				-webkit-appearance: none;
				color: #444;
				// margin-top: -1px;
				transition: box-shadow 0.2s ease-in-out;
			}
			&::-webkit-slider-thumb {
				width: 10px;
				-webkit-appearance: none;
				height: 10px;
				background: #fff;
				box-shadow: -340px 0 0 335px #6d4aed,  inset 0 0 0 2px #6d4aed;
				border-radius: 50%;
				transition: box-shadow 0.2s ease-in-out;
				position: relative;
				top: -8px;
			}
			&:active::-webkit-slider-thumb {
				background: #fff;
				// box-shadow: -340px 0 0 335px #6d4aed, inset 0 0 0 3px #6d4aed;
			}
		}
		// Firefox
		&::-moz-range-progress {
			background-color: #43e5f7; 
		}
		&::-moz-range-track {  
			background-color: #9a905d;
		}
		// IE
		&::-ms-fill-lower {
			background-color: #43e5f7; 
		}
		&::-ms-fill-upper {  
			background-color: #9a905d;
		}
	}


  input[type="range"]:disabled {
		outline: 0;
		border: 0;
		border-radius: 500px;
		min-width: 100%;
    cursor: pointer;
		margin: 15px 0 11px;
		transition: box-shadow 0.2s ease-in-out;
		// Chrome
		@media screen and (-webkit-min-device-pixel-ratio:0) {
			& {
				overflow: hidden;
				height: 10px;
				-webkit-appearance: none;
				// background-color: #ddd;
			}
			&::-webkit-slider-runnable-track {
				height: 5px;
				-webkit-appearance: none;
				color: #C4C4C4;
				// margin-top: -1px;
				// transition: box-shadow 0.2s ease-in-out;
			}
			&::-webkit-slider-thumb {
				width: 10px;
				-webkit-appearance: none;
				height: 10px;
				background: #fff;
				box-shadow: -340px 0 0 335px #C4C4C4,;
				border-radius: 50%;
				// transition: box-shadow 0.2s ease-in-out;
				position: relative;
				top: -8px;
			}
      &:disabled::-webkit-slider-thumb{
        background: #fff;
				box-shadow: -340px 0 0 335px #C4C4C4, inset 0 0 0 2px #C4C4C4;
      }
			// &:active::-webkit-slider-thumb {
			// 	background: #C4C4C4;
			// 	box-shadow: -340px 0 0 335px #C4C4C4, inset 0 0 0 3px #C4C4C4;
			// }
		}
		// Firefox
		&::-moz-range-progress {
			background-color: #43e5f7; 
		}
		&::-moz-range-track {  
			background-color: #9a905d;
		}
		// IE
		&::-ms-fill-lower {
			background-color: #43e5f7; 
		}
		&::-ms-fill-upper {  
			background-color: #9a905d;
		}
	}

input:not(:active) + #h4-container h4 {
	opacity: 0;
	// margin-top: -50px;
	pointer-events: none;
}


