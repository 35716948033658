.modal {
	min-height: 100vh;
	.modal-header {
		padding: 0;
		margin-bottom: 8px;

		.modal-title {
			font-weight: bold;
			font-size: 18px;
			line-height: 21px;
			color: map-get($theme-colors, "titles");
		}
		.close {
			margin: 0;
			padding: 0;
		}
	}
	.modal-content {
		border: none;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
		border-radius: 5px;
		padding: 31px 24px 24px 24px;
	}
	&-footer {
		padding: 0;
	}
	&-body {
		padding: 1px;
		margin-bottom: 16px;
	}
}
.fade {
	background: rgba(242, 244, 244, 0.95);
}
.modal-open .modal {
	overflow-y: hidden;
}
.modal-body {
	max-height: calc(100vh - 210px);
	// overflow-y: auto;
}
