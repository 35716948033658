.dropzone {
    border: 0;
    padding: 0;
    height: auto;
    display: flex;
    cursor: default;
    flex-wrap: wrap;

    .upload-img {
        width: 180px;
        height: 180px;
        background-size: cover;
        background-position: center;
        border: 3px solid map-get($theme-colors, "grey-20");
        border-radius: 5px;
        margin-right: 0.75rem;
        cursor: move;
        margin-bottom: 0.75em;
    }

    .hover-input {
        .box-img {
            border: 3px dashed map-get($theme-colors, "grey-20");
            border-radius: 5px;
            width: 180px;
            height: 180px;
            box-shadow: none;
            color: map-get($theme-colors, "titles");
            margin-bottom: 0.75em;
            cursor: pointer;
        }
    }
}
