.table {
	&.table-default {
		&.table-borderless {
			thead {
				border: 0;
				border-top: 1px;
				border-bottom: 1px;
				border-color: map-get($theme-colors, "light");
				border-style: solid;
			}
		}
		&.table-hover {
			tbody {
				tr {
					&:hover {
						background-color: rgba(map-get($theme-colors, "light"), 0.3);
					}
				}
			}
		}

		td,
		th {
			vertical-align: middle;
		}
	}
}

.rdt_Table {
	.thumb-product {
		width: auto;
		max-width: 80px;
		height: auto;
		max-height: 80px;
	}
}

.rdt_TableRow {
	overflow: visible !important;
}
// .rdt_TableHeader{
//     min-height: 10px !important;
// }
.rdt_TableCell div:first-child {
	overflow: visible !important;
	word-wrap: break-word !important;
}
// .cLiCmx div:first-child{overflow: visible !important;}
// .doBktq{min-height: 100px !important;}
// .goUYUm div:first-child{
//     white-space: pre-line !important;

// }
// .TEzDw{
//     min-width: 0px !important;
// }
// .kwRiGp{
//     min-height: 10px !important;
// }
// .eLwOET div:first-child{
//     text-overflow: clip !important;

// }
// .doBktq{
//     border:none !important
// }
// .cAeHSk div:first-child {
//     white-space: break-spaces !important;
// }
// .fWQfHP{
//     white-space: break-spaces !important;
// }

.product-image {
	min-height: 80px;
	display: flex;
	align-items: center;
	img {
		max-height: 80px;
		max-width: 80px;
	}
}
