.btn {
	padding: 0.55rem 1rem;
	font-size: 0.875rem;
	transition: all 0.5s ease;
	position: relative;

	border-radius: 3px !important;

	//Button small style
	&.btn-sm {
		font-size: 0.675rem;
		i {
			font-size: 1rem;
		}
	}

	//Button large style
	&.btn-lg {
		font-size: 1.15rem;
	}

	// Remove bootstrap focus
	&:focus {
		outline: 0;
		box-shadow: none;
	}

	// Round Style
	&.round {
		border-radius: 20px;
	}

	// Shadow Style
	&.shadow {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
		&:hover {
			box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2) !important;
		}
	}

	&.btn-icon-r,
	&.btn-icon-l {
		position: relative;
		i {
			float: left;
			margin-top: 2px;
			font-size: 1.2rem;
			margin-right: 10px;
		}
	}

	&.btn-icon-c-r,
	&.btn-icon-c-l {
		position: relative;
		i {
			position: absolute;
			font-size: 18px;
			padding: 3px;
			border-radius: 50%;
			width: 28px;
			height: 28px;
			line-height: 22px;
			text-align: center;
			top: 50%;
			transform: translate(0, -50%);
		}
	}
	&.btn-icon-c-l {
		padding-left: calc(0.55rem + 38px);
		i {
			left: 0.55em;
		}
	}
	&.btn-icon-c-r {
		padding-right: calc(0.55rem + 38px);
		i {
			right: 0.55em;
		}
	}
	&.btn-icon {
		display: inline-flex;
		align-items: center;
	}
	&.btn-action {
		background-color: map-get($theme-colors, "link") !important;
		&:hover {
			background-color: map-get($theme-colors, "link") !important;
		}
	}

	&.dropdown-toggle {
		&.no-txt {
			&::after {
				@extend .m-icon;
				font-size: 16px;
				width: auto;
				height: auto;
				margin: 0;
				display: flex;
				content: "keyboard_arrow_down";
				border: 0;
			}
		}
	}
	&-new-width {
		height: 40px;
		min-width: 150px !important;
	}
}

@include button("primary", false);
@include button("secondary", false);
@include button("success", false);
@include button("danger", false);
@include button("warning", false);
@include button("info", false);
@include button("light", true);
@include button("titles", false);
@include button("link", true);
@include button("white", true);

.btn-default {
	background: map-get($theme-colors, "white");
	border: 1px solid map-get($theme-colors, "grey-10");

	&:hover {
		background: darken(map-get($theme-colors, "white"), 5%);
		&.btn-icon-r,
		&.btn-icon-l {
			i {
				background: darken(map-get($theme-colors, "white"), 10%);
			}
		}
	}
	&.btn-icon-r,
	&.btn-icon-l {
		i {
			background: darken(map-get($theme-colors, "white"), 5%);
		}
	}
}

/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 24px;
	input {
		opacity: 0;
		width: 0;
		height: 0;
	}
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: map-get($theme-colors, "grey-20");
	-webkit-transition: 0.4s;
	transition: 0.4s;
	&:before {
		position: absolute;
		content: "";
		height: 16px;
		width: 16px;
		left: 4px;
		bottom: 4px;
		background-color: map-get($theme-colors, "white");
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}
}

input:checked + .slider {
	background-color: map-get($theme-colors, "primary");
}

input:focus + .slider {
	box-shadow: 0 0 1px map-get($theme-colors, "primary");
}

input:checked + .slider:before {
	-webkit-transform: translateX(16px);
	-ms-transform: translateX(16px);
	transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 24px;
}

.slider.round:before {
	border-radius: 50%;
}

.btn.filter:before {
	display: none;
}

#filter:checked ~ .btn.filter:before {
	display: block;
	background-color: #fafafa;
	background-repeat: no-repeat;
	background-position: center center;
	content: "";
	width: 100%;
	height: 40px;
	position: absolute;
	bottom: -30px;
	left: 0px;
	margin-top: -11px;
	animation: fadein 1s normal;
}

input:focus + .slider {
	box-shadow: 0 0 1px map-get($theme-colors, "primary");
}

input:checked + .slider:before {
	-webkit-transform: translateX(16px);
	-ms-transform: translateX(16px);
	transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 24px;
}

.slider.round:before {
	border-radius: 50%;
}

.btn.filter:before {
	display: none;
}

#filter:checked ~ .btn.filter:before {
	display: block;
	background-color: #fafafa;
	background-repeat: no-repeat;
	background-position: center center;
	content: "";
	width: 100%;
	height: 40px;
	position: absolute;
	bottom: -30px;
	left: 0px;
	margin-top: -11px;
	animation: fadein 1s normal;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.btn-primary,
.btn-secondary,
.btn-success,
.btn-danger,
.btn-warning,
.btn-dark,
.btn-info {
	.lds-ring div {
		border: 1px solid map-get($theme-colors, "white");
		border-color: map-get($theme-colors, "white") transparent transparent
			transparent;
	}
}

.outline {
	.lds-ring div {
		border: 1px solid map-get($theme-colors, "dark");
		border-color: map-get($theme-colors, "dark") transparent transparent
			transparent;
	}
}
