/* Metrcis */
.metrics-orders {
	select {
		width: 160px;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		color: map-get($theme-colors, "titles");
	}

	> p {
		color: map-get($theme-colors, "grey-30");
		font-size: 14px;
		font-weight: 500;
		margin-top: 5px;
		padding: 0 10px;
	}
}
.metrics {
	width: 130px;
	.metrics-title {
		color: map-get($theme-colors, "grey-30");
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
	}

	.metrics-value {
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 28px;
		color: map-get($theme-colors, "grey-30");
	}

	.metrics-icons-a {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		color: map-get($theme-colors, "success");
		display: flex;
		justify-content: start;
		align-items: center;
		align-content: center;
	}
	.metrics-icons-b {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		color: map-get($theme-colors, "danger");
		display: flex;
		justify-content: start;
		align-items: center;
		align-content: center;
	}
}

.filter-order-spdp {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 10px;
	> div {
		width: 350px;
	}
}

.filter-search-spdp {
	width: 522px;
}

.btn-filtered {
	background-color: map-get($theme-colors, "primary");
	color: map-get($theme-colors, "white");
	border: none;
	border-radius: 4px;
	height: 26px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 2px 5px;
	margin-right: 8px;
	> i {
		font-size: 18px;
		margin-left: 2px;
	}
}

.btn-filtered:focus {
	outline: thin dotted;
	outline: 0px auto -webkit-focus-ring-color;
	outline-offset: 0px;
}

@media (max-width: 892px) {
	.filter-order-spdp {
		> div {
			width: 100%;
		}
	}

	.filter-search-spdp {
		margin-top: 10px;
		width: 100%;
	}
}

@media (max-width: 550px) {
	.filter-orders {
		flex-direction: column;
		align-items: center;

		.filter-date {
			width: 100%;
			margin-bottom: 10px;
		}

		.filter-btn {
			width: 100%;
		}
	}
}

.order-list {
	&-client {
		line-height: 12px;
		font-size: 12px;
		p:last-child {
			margin-bottom: 0;
			color: map-get($theme-colors, "gray-60");
		}
		&-name {
			color: map-get($theme-colors, "secondary");
			font-weight: bold;
		}
	}
	&-order {
		line-height: 12px;
		font-size: 12px;
		p:last-child {
			margin-bottom: 0;
		}
		&-code {
			font-weight: bold;
			height: 12px;
		}
	}
	&-status {
		line-height: 14px;
		font-size: 12px;
		color: map-get($theme-colors, "icons");
		text-align: center;
		p:last-child {
			margin-bottom: 0;
		}
		&-Canceled {
			color: map-get($theme-colors, "danger");
		}
		&-InProcessing {
			color: map-get($theme-colors, "warning");
		}
		&-Finalized {
			color: map-get($theme-colors, "success");
		}
	}
	&-products {
		display: flex;
		background: map-get($theme-colors, "grey-50");
		border-radius: 33px;
		color: map-get($theme-colors, "white");
		width: 56px;
		height: 24px;
		align-self: center;
		justify-content: center;
		align-items: center;
		line-height: 24px;
		p:last-child {
			margin-bottom: 0;
		}
	}
	&-modalities {
		line-height: 14px;
		font-size: 12px;
		p:last-child {
			margin-bottom: 0;
		}
		&-title {
			font-weight: bold;
			word-break: keep-all;
			width: 8em;
			height: 1.8em;
		}
	}
	&-origin {
		line-height: 12px;
		font-size: 12px;
		p:last-child {
			margin-bottom: 0;
		}
		&-title {
			font-weight: bold;
			line-height: 20px;
		}
	}
	&-total {
		line-height: 12px;
		font-size: 12px;
		p:last-child {
			margin-bottom: 0;
		}
	}
	&-payment {
		display: flex;
		line-height: 14px;
		font-size: 12px;
		flex-direction: column;
		text-align: center;
		align-items: center;
		align-self: center;
		p:last-child {
			margin-bottom: 0;
		}
		span {
			margin-bottom: 0.8em;
		}
	}
	&-details {
		color: map-get($theme-colors, "secondary");
		text-align: center;
	}
}

.filter-orders {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	align-content: flex-end;
	flex-direction: row;

	.filter-date {
		width: 550px;
		> label {
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.06em;
			text-transform: uppercase;
			color: #474162;
		}
		.group-btn {
			display: flex;
			flex-direction: row;

			> button {
				display: inline-block;
				cursor: pointer;
				box-shadow: 0 0 0 0;
				outline: 0;
				background: transparent;
				height: 38px;
				padding: 0 10px;
				border: 1px solid #dee2e6;
				color: #c4c4c4;
				font-size: 12px;
				font-weight: 500;
				text-transform: uppercase;

				& + button {
					border-left: none;
					flex: 1;
				}
				&:first-child {
					border-radius: 5px 0 0 5px;
				}
			}
			.dropdown {
				display: inline-block;
				cursor: pointer;
				box-shadow: 0 0 0 0;
				outline: 0;
				background: transparent;
				height: 38px;
				padding: 0 0px;
				border: 1px solid #dee2e6;
				color: #c4c4c4;
				font-size: 11px;
				font-weight: 500;
				text-transform: uppercase;
				border-radius: 0 5px 5px 0;

				& + button {
					border-left: none;
					flex: 1;
				}
				.dropdown-toggle {
					background-color: transparent;
					font-size: 12px;
					font-weight: 500;
					text-transform: uppercase;
					.material-icons {
						font-size: 16px;
					}
					span {
						justify-self: center;
					}
				}
			}
			// > button:focus {
			// 	background-color: #01bfb5;
			// 	color: #fff;
			// 	border: none;
			// 	height: 38px;
			// }
			// .calendar-toggle[aria-expanded="true"] {
			// 	background-color: #01bfb5;
			// 	color: #fff;
			// 	border: none;
			// 	margin: none;
			// 	height: 37px;
			// 	border-radius: 0 5px 5px 0 !important;
			// }
			.active-data-btn {
				background-color: #01bfb5;
				color: #fff;
				border: none;
				height: 38px;
				> .calendar-toggle {
					&:active {
						background-color: #01bfb5;
						color: #fff;
						border: none;
						margin: none;
						height: 37px;
						border-radius: 0 5px 5px 0 !important;
					}
				}
			}
		}
	}

	.filter-search {
		width: 350px;
	}

	.filter-btn {
		display: flex;
		flex-direction: row;

		.import {
			height: 42px;
			display: flex;
			background: rgba(108, 214, 145, 0.3);
			border-radius: 3px;
			color: #6cd691;
		}
	}
}
