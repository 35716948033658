.login {
    width: 70%;
    margin:70px;
    display: flex;
    flex-direction: row;
    border-radius: 10px 0px 0px 10px;
    @media (max-width: 767px){
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 800px;
    }
   
    .a{
        padding-left: 40px;
        padding-top: 10%;
        background-color: map-get($theme-colors, "secondary");
        height: 448px;
        width: 50%;
        color: map-get($theme-colors, "white");
        font-weight: 600;
        border-radius: 10px 0 0 10px;
            h2::after{
                display: block;
                width: 100px;
                border-bottom: 3px solid map-get($theme-colors, "white");
                content: '';
                position: absolute;
                margin-top: 20px;
            }
            p{
                margin-top:50px;
                font-size: 12px;
                line-height: 16px;
            }
            img{
                width: 170px;
            }
            @media (max-width: 767px){
                position: absolute;
                width: 80%;
                height: 200px !important;
                border-radius: 10px 10px 0px 0px;
                z-index: 1;
            }
    }
    
    .b{
        background-color: map-get($theme-colors, "white");
        height: 448px;
        width: 50%;
        border-radius: 0 10px 10px 0;
        text-align: center;
        overflow: hidden;
        .initial{
            width:100%
        }
        .erro{
            i{
                font-size:12px
            }
        }
        
        h2{
            padding-bottom: 1.75rem;
            font-weight: 700;
        }
        h2::after{
            display: block;
            width: 50px;
            border-bottom: 3px solid map-get($theme-colors, "titles");
            content: '';
            position: absolute;
            margin-top: 20px;
            left:50%;
            transform: translateX(-50%);
        }
        .reset-password{
            color:map-get($theme-colors, "grey-30");
            font-size: 12px;
        }
        .footer-login{
            position: absolute;
            bottom: 20px;
            text-align: left;
            font-size: 12px;
            width: 90%;
            margin: auto;
            .desenvolvedor a{
                color:map-get($theme-colors, "grey-30");
                margin-left:20px
            }
            .ajuda a{
                color:map-get($theme-colors, "titles");
                margin-left: 5px;
            }
        }
        .success{
            color: map-get($theme-colors, "success");
            font-weight: 600;
            .material-icons{
                font-size: 40px !important;
            }  
        }
        .top-login{
            position: absolute;
            top: 20px;
            text-align: left;
            font-size: 12px;
            width: 90%;
            margin: auto;
            .ajuda a{
                color:map-get($theme-colors, "titles");
                margin-left: 5px;
            }
        }
        .roleContainer{
            border: 1px solid map-get($theme-colors, "grey-20");
            box-sizing: border-box;
            border-radius: 5px;
            text-align: left;
            
            .itemRole{
                list-style: none;
                position: relative;
                border-bottom: 1px solid map-get($theme-colors, "grey-20");
                &:last-child {
                    border-bottom: 0;
                }
                .name{
                    font-weight: 600;
                }
                .role{
                    font-size: 12px;
                    display: block;
                }
                .go{
                    a{
                        color:map-get($theme-colors, "primary");
                    }
                }
                img{
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                }
            }
        }
        @media (max-width: 767px){
            position: absolute;
            margin-top: 150px;
            width: 80%;
            border-radius: 0px 0px 10px 10px;
            
        }
        
    }
}