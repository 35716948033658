/* Theme Colors */
$theme-colors: (
    "bgNeo":    #F2F4F4, //Background
    "black":    #000000, //Pure Black
    "white":    #ffffff, //Pure White
    "primary":  #00BFB5,
    "secondary":#6D4AED,
    "success":  #6CD691,
    "danger":   #FF5B5C,
    "warning":  #FDAC41,
    "info":     #7A9FEB,
    "icons":    #747E8B, //Blueish Grey
    "grey-10":  #F5F5F5, //Gray 10%
    "grey-20":  #E9E9E9, //Grey 20%
    "grey-30":  #DBDBDB, //Grey 30%
    "grey-50":  #BCBEC0, //Grey 50% (Neo Cinza)
    "grey-60":  #939698, //Grey 60%
    "grey-65":  #8D8D8D, //Grey 70%
    "grey-70":  #707070, //Grey 75%
    "grey-80":  #5A5E61, //Grey 80%
    "grey-90":  #2E2E34, //Grey 90%
    "grey-95":  #1A1A1A, //Grey 95%
    "light":    #E9ECEC,
    "titles":   #4B5F79, //Virou Deep Blue
    "link":transparent,
);

/* Autosetup variables prefix 'as' */
$as-bg-color:map-get($theme-colors, "neo-bg");
$as-primary-color:map-get($theme-colors, "primary");
