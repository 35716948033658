.materials{
  text-align:center;
  width:100%;
  height: 100ch;
  
  h1{
    font-weight: 200;
  }
}
.materials-card{
  cursor: pointer;
  &:hover{
    border-bottom: 3px solid map-get($theme-colors, "primary");
    margin-bottom: -3px;
  }
  i{
    background-color:map-get($theme-colors, "grey-20");
    color:  #80868B;
    padding:10px;
    border-radius: 5px;
  }
}
.card-title{
  font-size: 16px;
  font-weight: 700;
}
