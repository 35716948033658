.detail {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid map-get($theme-colors, "grey-10");

    .detail-tabs {
        button{
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: map-get($theme-colors, "titles");
            width: auto;
            border: none;
            padding: 0 2.5em 15px 2.5em;
            background: map-get($theme-colors, "link");
            cursor: pointer;
        }
        button:focus {
            outline: 0px auto -webkit-focus-ring-color;
            outline-offset: 0px;
        }
        .actived {
            border-bottom: 4px solid map-get($theme-colors, "secondary");
            color: map-get($theme-colors, "secondary");
        }
    }

    .detail-invoice {
        margin-right: 6px;
        > button {
            display: flex;
            justify-content: center;
            align-content: center;
            padding: 8px 10px;
            cursor: pointer;
            box-shadow: 0 0 0 0;
            outline: 0;
            border: none;
            background: rgba(122, 159, 235, 0.3);
            border-radius: 5px;

            > i {
                color: map-get($theme-colors, "info");
                font-size: 20px;
            }
        }
    } 
}

.detail-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;

    .box-one {
        width: 32.6%;
        height: 250px;
        padding: 25px 20px;

        .box-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            margin-bottom: 15px;
            h2 {
                padding-top: 5px;
                font-weight: bold;
                font-size: 17px;
            }
            .success{
                margin-left: 8px;
                background: map-get($theme-colors, "success");
                color: map-get($theme-colors, "white");
                text-align: center;
                padding: 10px 10px;
                border-radius: 5px;
                font-weight: 500;
            }
            .warning {
                margin-left: 8px;
                background: map-get($theme-colors, "warning");
                color: map-get($theme-colors, "white");
                text-align: center;
                padding: 10px 10px;
                border-radius: 5px;
                font-weight: 500;
            }
            .danger {
                margin-left: 8px;
                background: map-get($theme-colors, "danger");
                color: map-get($theme-colors, "white");
                text-align: center;
                padding: 10px 10px;
                border-radius: 5px;
                font-weight: 500;
            }
            label {
                padding: 10px;
                border-radius: 5px;
                background: map-get($theme-colors, "bgNeo");
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 25px;
                color: map-get($theme-colors, "titles");
            }
        }

        .text-orders {
            margin: 0;
        }

        span {
            font-size: 14px;
            color:map-get($theme-colors, "titles");
            margin-bottom: 8px;
        }
    }

    div + div {
        margin-left: 15px;
    }

    .box-two {
        width: 68%;
        height: 250px;
        padding: 25px 20px;
        display: flex;
        flex: 1;
        .status {
            display: flex;
            justify-content:space-between;

            label {
                padding: 10px;
                border-radius: 5px;
                font-weight: 500;
            }
    }
    }

    .box-three {
        width: 100%;
        padding: 25px 20px;
        .box-container-orders {
            display: flex;
            .box-header {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                width: 40%;
                .box-img-orders {
                    margin-top: 20px;
                    margin-left: 7px;
                    margin-bottom: 5px;
                    max-width: 50px;
                    max-height: 300px;
                    >img {
                        width: 100px;
                        max-width:300px;
                        max-height:300px;
                    }
                }
            }
            .box-body {
                width: 100%;
                display: flex;
                flex-direction: row;

                > div {
                    display: flex;
                    flex-direction: column;
                    margin: 20px;
                    padding: 10px 30px;
                    color: map-get($theme-colors, "icons");
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
        .box-table {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .total {
                width: 35%;
                padding-right: 30px;
                padding-bottom: 20px;
                border-bottom: 1px dashed #E9ECEC;
                display: flex;
                justify-content: flex-end;
                

                > div {
                    display: flex;
                    flex-direction: column;

                    .title-1 {
                        padding-right: 100px;
                        color: map-get($theme-colors, "icons");
                    }
                    .title-2 {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }        
}

/* area do historico */
.card-historic-description {
    padding: 10px 20px; 
    color: map-get($theme-colors, "grey-30");
    font-size: 12px;
    font-weight: 400;
}

.card-historic {
    border: 1px solid map-get($theme-colors, "grey-10");
    border-radius: 5px;

    .card-historic-title {
        padding: 8px 20px; 
        color: map-get($theme-colors, "icons");
        font-size: 14px;
        font-weight:bold;
    }
}

.order-label {
    text-transform: uppercase;
    height: 80px;
    >h2 {
        margin: 0;
        label {
                margin: 0;
        }
    }
    >span {
        color: map-get($theme-colors, "titles");
        font-size: 12px;
    }

}

@media only screen and (max-width: 1200px) {
    /* .detail-container .box-two .status label {
        padding: 3px 0;
    } */

    .order-label {
        >span {
                margin-top: 3px;
                margin-bottom: 5px;
        }
    }
}

@media only screen and (max-width: 990px) {
    .detail-container {
        flex-direction: column;

        .box-one {
                width: 100%;
                margin: 0;
                margin-bottom: 15px;
        }
        div + div {
                margin-bottom: 15px;
        }
    
        .box-two {
                width: 100%;
                margin: 0;
        }
        
        .box-three {
            width: 100%;
            margin-top: -10px;
            .box-container-orders {
                display: flex;
                flex-direction: column;
                .box-header {
                    width: 100%;
                }
                .box-body {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    margin-left: 0;
                    > div {
                        display: flex;
                        flex-direction: column;
                        margin: 5px 20px;
                        padding: 0;
                        color: map-get($theme-colors, "icons");
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }
        }    
    }
}

@media only screen and (max-width: 767px) {
    .order-label {
        >span {
            margin-top: 5px;
            margin-bottom: 0;
        }
    }
}


.download-pdf-xml {
    background: map-get($theme-colors, "white");
    border: 3px dashed map-get($theme-colors, "grey-10");
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    cursor: pointer;
    height: 50px;

    p {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        align-content: center;
        font-weight: 500;
        cursor: pointer;
        color: rgb(161, 163, 165);

        i {
                cursor: pointer;
                font-size: 25px;
                color: rgb(161, 163, 165);
        }
    }

    .success {
        display: flex;
        justify-content: center;
        align-self: center;
        padding: 0;
        margin: 0;
        .success-icon {
                color: #98ff98;
                font-size: 40px;
        }
    }
}
.download-pdf-xml:active {
    background:rgba(122, 159, 235, 0.3);
}
.download-group {
    border: 2px solid map-get($theme-colors, "grey-10");
    height: 40px;
    display: flex;
    justify-content: space-between;

    .download-group-label {
        padding: 0 10px;
        display: flex;
        align-items: center;
        align-self: center;
        align-content: center;
        
        >i {
                padding-right: 10px;
                color: map-get($theme-colors, "icons");
        }
    }

    .download-group-icons {
        padding: 0 10px;
        display: flex;
        align-items: center;
        align-self: center;
        align-content: center;

        >button {
            cursor: pointer;
            padding-top: 10px;
            background: map-get($theme-colors, "link");
            border: none;
            outline: none
        }
        >a {
            cursor: pointer;
            padding-top: 10px;
            color: map-get($theme-colors, "icons");
        }
    }
    
}

.date-orders {
    width: 215px;
    background: map-get($theme-colors, "grey-50");
    display: flex;
    justify-content:center;
    align-items: center;
    align-self: center;
    align-content: center;
    border-radius: 4px;

    i {
        color: map-get($theme-colors, "titles");
        padding: 8px 9px;
        font-size: 20px;
    }
}

.status-select, .status-select:focus {
    width: 200px;
    padding: 12px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
    
    option, option:focus {
        padding: 10px 50px;
        border-radius: 5px;
        border: none;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        box-shadow: 0 0 0 0;
        border: 0 none;
        outline: 0;
    }
}

.separete {
    background-color: map-get($theme-colors, "titles");
    color: map-get($theme-colors, "white");
}

.separete:hover {
    background-color: map-get($theme-colors, "titles") !important;
}

.separete:active {
    background-color: map-get($theme-colors, "titles") !important;
}

.separete:focus {
    background-color: map-get($theme-colors, "titles") !important;
}

.counter-price {
    display: flex;
    justify-content: center;
    align-items: center;

    >button {
        color: map-get($theme-colors, "titles");
        background-color: map-get($theme-colors, "white");
        border: none;
        cursor: pointer;
        text-align: center;
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
    }

    >input {
        width: 50px;
        height: 30px;
        border: none;
        padding: 5px;
        text-align: center;
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
    }
}

.edit-price {
    max-width:150px;
    .value-price {
        display: flex;
        padding: 0;
        margin: auto 0;
        >label {
            position: relative;
            top: 6px;
            left: 25px;
        }
        >input {
            padding: 5px 0 5px 27px;
            border: 1px solid #00BFB5;
            box-sizing: border-box;
            border-radius: 5px !important;
        }
        >input:focus {
            outline: thin dotted;
            outline: 0px auto -webkit-focus-ring-color;
            outline-offset: 0px;
        }
}

    .btn-price {
        display: flex;
        justify-content: flex-end;
        position: relative;
        
        >div {
            position: absolute;
            display: flex;
            >button {
                padding: 0;
                width: 28px;
                height: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                >i {
                    font-size:20px;
                }
            }
        }
        
    }
}
.btn-sku-default {
    background: map-get($theme-colors, "bgNeo");
    border: solid 3px map-get($theme-colors, "bgNeo");
    margin-right: 5px;
    font-weight: 400;
    width: 64px;
    height: 40px;
    border-radius: 3px;
    cursor: pointer;
}

.btn-sku-default:hover {
    background: map-get($theme-colors, "primary");
    border: solid 3px map-get($theme-colors, "primary");
    color: #ffffff;
    font-weight: 500;
}

.btn-sku-default:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
}

.btn-sku-active {
    background: map-get($theme-colors, "primary");
    border: solid 3px map-get($theme-colors, "primary");
    color: #ffffff;
    margin-right: 5px;
    font-weight: 400;
    width: 64px;
    height: 40px;
    border-radius: 3px;
    cursor: pointer;
}

.btn-sku-active:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
}

.btn-color-default {
    background: map-get($theme-colors, "bgNeo");
    border: solid 3px map-get($theme-colors, "bgNeo");
    font-weight: 400;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    >button {
        border-radius: 3px;
        border: none;    
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
    >button:focus {
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
    }
}
.btn-color-default:hover {
    border: solid 3px map-get($theme-colors, "primary");
    border-radius: 3px;
}
.btn-color-active {
    background: map-get($theme-colors, "bgNeo");
    border: solid 3px map-get($theme-colors, "primary");
    border-radius: 3px;
    font-weight: 400;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    >button {
        border-radius: 3px;
        border: none;    
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
    >button:focus {
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
    }
}

.tooltipOrders {
    cursor: help;
    display: block;
    position: relative;
    color: #FFFFFF !important;
    font-size: 15px;
    width: 150px;
    height: 40px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: 500;
    padding: 8px 10px 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;

    >span {
        position: absolute;
        text-align: center;
        visibility: hidden;
        right: 0;
        background-color: map-get($theme-colors, "black");
        color: map-get($theme-colors, "white") !important;
        border-radius: 5px;
        padding: 5px 10px;
    }

    span:after {
        content: '';
        position: absolute;
        top: 100%;
        right: 10%;
        margin-left: -8px;
        width: 0; height: 0;
        border-top: 8px solid map-get($theme-colors, "black");
        border-right: 8px solid map-get($theme-colors, "link");
        border-left: 8px solid map-get($theme-colors, "link");
    }
}
.tooltipOrders:hover span {
    visibility: visible;
    opacity: 0.9;
    bottom: 40px;
    right: 0;
    z-index: 999;
}

.filter-order {
    select {
        width: 300px;
    }
}

@media (max-width: 992px) {
    .filter-order {
        select {
            width: 100%;
        }
    }
}



