.toast-push {
    background-color: map-get($theme-colors, "white");
    z-index: 1;
    width: 300px;
    position: absolute;
    bottom: 5%;
    right: 1%;
    animation-duration: 5s;
    animation: go-back 1s;

    .toast-push-header {
        background-color: map-get($theme-colors, "success");
        border-radius: 5px 5px 0 0;
        color: map-get($theme-colors, "white");
        padding: 2px 10px;
        margin: 0;
        border-bottom: 1px solid map-get($theme-colors, "grey-20");
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        align-content: center;

        >button {
            color: map-get($theme-colors, "white");
        }

        >img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: map-get($theme-colors, "white");
        }

        >span {
            display: flex;
            padding-bottom: 10px;
            margin: 0;
            color: map-get($theme-colors, "white"); 
            font-style: normal;
            font-weight: 500;
            font-size: 17px;

        }
    }
    .toast-push-body {
        padding: 15px 10px; 
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        border-radius: 0 0 5px 5px;

    }
}

@keyframes go-back {
    0% {
        transform: translateX(100px);
        overflow-x: hidden;
    }
    100% {
        transform: translateX(0);
        overflow-x: hidden;
    }
}
.push-order {
    color: map-get($theme-colors, "white");
    background:map-get($theme-colors, "success");
    border: none; 
    cursor: pointer;
    text-align: left;
}
.push-order:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
}