.suggestions {
    display: flex;
    width: 80%;
    justify-content: center;
    align-self: center;
    align-items: center;
    align-content: center;
    padding: 8px;
    color:map-get($theme-colors, "icons");
    border: 1px solid map-get($theme-colors, "grey-20");
    border-radius: 4px;
}