.empty .list {
	padding: 10%;
}
.modal-header {
	padding-bottom: 0;
}
.modal-header,
.modal-footer {
	border: none;
}
.conditions {
	padding: 0px;
	height: 400px;
	overflow-y: scroll;

	&::-webkit-scrollbar-track {
		// -webkit-box-shadow: inset 0 0 6px $secondary-color;
		border-radius: 10px;
		background-color: map-get($theme-colors, "bgNeo");
	}

	&::-webkit-scrollbar {
		width: 6px;
		background-color: map-get($theme-colors, "bgNeo");
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		// -webkit-box-shadow: inset 0 0 6px $secondary-color;
		background-color: map-get($theme-colors, "grey-60");
	}
	ul {
		list-style: none;
		margin-left: 0px;
		padding-left: 0px;
		li {
			padding: 1rem;
			border-bottom: 1px solid map-get($theme-colors, "grey-20");
			font-size: 16px;
			line-height: 18px;
			color: map-get($theme-colors, "titles");
			cursor: pointer;
		}
	}
}
.search-list {
	.checkbox-list {
		height: 400px;
	}
}
.checkbox-list {
	padding: 0px;
	max-height: 400px;
	overflow-y: auto;
	&::-webkit-scrollbar-track {
		// -webkit-box-shadow: inset 0 0 6px $secondary-color;
		border-radius: 10px;
		background-color: map-get($theme-colors, "bgNeo");
	}

	&::-webkit-scrollbar {
		width: 6px;
		background-color: map-get($theme-colors, "bgNeo");
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		// -webkit-box-shadow: inset 0 0 6px $secondary-color;
		background-color: map-get($theme-colors, "grey-60");
	}

	ul {
		list-style: none;
		margin-left: 0px;
		padding-left: 0px;
		li {
			padding: 0.5rem 1rem;
			font-size: 16px;
			line-height: 18px;
			color: map-get($theme-colors, "titles");
			cursor: pointer;
			border: 0;
		}
	}
}

.conditions {
	&-button {
		margin-left: -45px;
		z-index: 1;
	}
}

.warning-discount {
	font-size: 14px;
}

.voucher-price {
	height: 38px;
	&-input {
		font-size: 13px;
		font-weight: 500;
	}
}

.modal-footer {
	justify-content: center;
}
