.product-card-list {
	border-radius: 3px;
	height: 125px;
	width: 85px;
	margin: 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 0;
	position: relative;
	padding: 3px;
	&-title {
		margin-bottom: -5px;
	}
	&-subtitle {
		color:map-get($theme-colors, "primary");
		font-size: 10px;
	}

	.bottom-tags {
		font-size: 10px;
		position: absolute;
		bottom: 0;
		margin-bottom: 5px;
		text-align: center;
		
		.product-tag {
			height: 18px;
			width: 79px;
			border-radius: 3px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			padding: 2px 5px;
			margin-top: 3px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}
	}

	.top-tags {
		font-size: 10px;
		position: absolute;
		top: 0;
		right: 0;
		margin-right: 5px;
		margin-top: 5px;

		.product-tag {
			height: 18px;
			width: auto;
			border-radius: 3px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			padding: 2px 5px;
		}
	}
}
.product-card-detail {
	width: 100%;
	height: 200px;
	background-repeat: no-repeat;
	background-size: cover;
}

.details-tags {
	font-size: 10px;
	border-radius: 3px;
	width: 95px;
	text-align: center;
	padding: 2px 5px;
	height: 18px;
	line-height: 12px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.tag-discount {
	font-size: 11px;
	height: 20px;
	width: auto;
	border-radius: 3px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding: 2px 5px;
}

.collection {
	&-color {
		display: flex;
		flex-direction: row;
		align-items: center;
		&-button {
			background-color:map-get($theme-colors, "bgNeo");
			width: 130px;
			height: 40px;
			border-radius: 3px;
			cursor: pointer;
		}
		&-preview {
			margin-right: 1em;
			width: 30px;
			height: 30px;
			background:map-get($theme-colors, "primary");
			border-radius: 3px;
		}

		&-text {
			flex: 0;
			margin: 0;
			text-transform: uppercase;
		}
	}
}

.tag {
	&-preview {
		width: 174px;
		height: 30px;
		border-radius: 3px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
